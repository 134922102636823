@import "variables.scss";

@font-face {
  font-family: 'appFont';
  src: url('/assets/fonts/HOMEPAGEBAUKASTEN-BOOK.OTF');
}

@font-face {
  font-family: 'appFontBold';
  src: url('/assets/fonts/HOMEPAGEBAUKASTEN-BOLD.TTF');
}

//font face only for '!' symbol while appFontBold is declared
@font-face {
  font-family: 'appFontBold';
  src: url('/assets/fonts/Arimo-Medium.ttf');
  unicode-range: U+0021;
}

@font-face {
  font-family: 'proSansRegular';
  src: url('/assets/fonts/ProductSans-Regular.ttf');
}

@font-face {
  font-family: 'proSansBold';
  src: url('/assets/fonts/ProductSans-Bold.ttf');
}

span, p, a, div, td, tr, table, article, section, nav, label, fieldset, input, select, form, button, textarea, .p-paginator .p-paginator-pages .p-paginator-page {
  font-family: 'appFont', sans-serif;
  color: $main-text-color;
  font-size: 13px;
  font-weight: normal;
}

.p-inputtext, .p-menu .p-menuitem-link .p-menuitem-text {
  font-family: 'appFont', sans-serif !important;
  color: $main-text-color !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

table, tr, td {
  margin: 0;
  padding: 0;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
  outline: none;
  vertical-align: baseline;
  background: none;
}

/* titles */

h4.mb-1 {
  margin-bottom: 25px !important;
  color: $light-grey;
  font-family: 'appFontBold', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 0;
}

.w-90-p {
  width: 90% !important;
}

.settings-title {
  color: $main-text-color;
  text-transform: uppercase;
  font-size: 17px !important;
  padding-top: 51px;
  margin: 0;
  padding-left: 45px;
  padding-bottom: 18px;
  font-family: 'appFontBold', sans-serif;
  font-weight: normal;
}

/* tooltip */

.p-tooltip {
  .p-tooltip-text {
    background-color: $white;
    border: 1px solid $main-text-color;
    font-size: 12px;
    white-space: pre-line;
    width: fit-content;
    line-height: 15px;
    font-family: 'appFont', sans-serif;
    color: $main-text-color;
    border-radius: 5px;
  }

  .p-tooltip-arrow {
    z-index: 1;
    border-width: 5px;
    border-color: transparent;
    scale: 1!important;
    &::before {
      z-index: 0;
      display: inline-block;
      content: "";
      border-style: solid;
      border-width: 4px;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-bottom: none;
      border-top-color: $main-text-color;

      &::before {
        border-color: $white transparent transparent transparent;
        left: -5px;
        top: -4px;
        transform: rotate(90deg);
      }
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-top: none;
      border-bottom-color: $main-text-color;

      &::before {
        border-color: transparent transparent transparent $white;
        left: -4px;
        top: -3px;
        transform: rotate(270deg);
      }
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-right: none;
      border-left-color: $main-text-color;

      &::before {
        border-color: transparent transparent white transparent;
        left: -5px;
        top: -4px;
        transform: rotate(90deg);
      }
    }
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-left: none;
      border-right-color: $main-text-color;

      &::before {
        border-color: transparent $white transparent transparent;
        left: -3px;
        top: -4px;
      }
    }
  }
}

.mini-report-tooltip.p-tooltip {
  background-color: $white;
  border: 1px solid #707070;
  border-radius: 0;
  width: fit-content;
  max-width: 320px;
  padding: 6px 2px;

  .p-tooltip-text {
    color: $dark-backgroung-color;
    background-color: $white;
    font-size: 12px;
    line-height: 15px;
  }

  .p-tooltip-arrow {
    display: none;
  }

}



.pointer {
  cursor: pointer;
}

/* p-overlaypanel */

.p-overlaypanel {
  background-color: $main-background-color !important;
  border: 2px solid $bg-light-nude !important;
  border-radius: $modal-radius !important;

  &.info-overlay {
    background-color: $white !important;
    max-width: 550px;

    &::before {
      border-bottom-color: $bg-light-nude;
    }
    &.dark-tooltip-info {
      border-radius: 3px!important;
      background-color: #4E4E4E!important;
      border-color: #4E4E4E!important;
      max-width: 292px;
      div {
        color: #FFFFFF;
        line-height: 15px;
      }
      &::before, &::after {
        display: none;
      }
    }
  }
}

/* p-menu */

.p-menu.p-menu-overlay {
  background-color: $main-background-color !important;
  border: 2px solid $bg-light-nude !important;
  border-radius: $modal-radius !important;
}

.p-menu .p-submenu-header {
  background: transparent;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  margin-right: 10px;
}

/* lang menu */

.globe-lang-menu {
  background-color: $white !important;

  &.p-menu-overlay {
    background-color: $white !important;
  }

  .pi {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-left: 13px;
    background-size: 100%;
  }
}

/* general-side-menu*/

.general-side-menu {
  padding-top: 50px;
  margin: 0;
  background: transparent;
  border: none;
  .p-menuitem > .p-menuitem-content {
    height: 100%;
  }

  &.p-menu {
    max-width: 160px;
  }

  .p-menu-list {
    height: 100%;
  }

  li {
    cursor: pointer !important;
    text-decoration: none;
    max-height: 64px;
    min-height: 30px;
    height: 100%;

    &.p-submenu-header {
      padding-top: 0;
      height: 25px;

      span {
        color: $light-grey;
        text-transform: uppercase;
        font-family: 'appFontBold', sans-serif;
      }

      &:not(:first-child) {
        padding-top: 25px;
        border-top: 1px solid #D1C9C5;
        padding-bottom: 25px;
        height: auto;
        margin-bottom: 0 !important;
      }

      &:first-child {
        height: auto;
        padding-bottom: 23px;
        margin-top: 3px;
      }
    }

    .p-menuitem-link {
      box-shadow: none !important;
      position: relative;
      height: 100%;

      .p-menuitem-text {
        font-size: 11px !important;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        font-family: 'appFontBold', sans-serif !important;
        margin-top: 2px;
        line-height: 12px;
      }

      &[disabled='true'] {
        cursor: not-allowed !important;
      }

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: 1px;
      }

      &[isActive='true'], &:hover, &.p-menuitem-link-active {
        .p-menuitem-text {
          color: $white !important;
        }
      }
    }
  }
}

/* header icon buttons */

.header-icon-btn {
  padding-top: 9px;
}

.header-icon-btn .header-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-size: 100%;
}

.header-icon-btn:hover, .header-icon-btn:focus {
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

// data boxes
.data-boxes {
  .at-box {
    box-shadow: 0 5px 10px #EEECEF80;
    border: 1px solid #EEECEF;
    height: 141px;
    padding: 21px 20px 19px 25px;
    margin-right: 9px;
    border-radius: 5px !important;
    position: relative;

    .count {
      text-align: left;
      font-size: 27px;
      font-family: 'appFontBold', sans-serif;
    }

    i {
      display: inline-block;
      float: right;
      background-size: 100%;
    }

    .text {
      position: absolute;
      bottom: 17px;
      line-height: 18px;
      width: calc(100% - 40px);
    }

    &.active {
      border-color: $main-text-color;
    }
  }
}

/* inputs */

input[type=text], input[type=url], input[type=password], .add-rule-dialog.p-inputnumber-input, input[type=email] {
  background: white !important;
  color: $main-text-color !important;
  height: 34px;
  border: 1px solid $border-separator;
  border-radius: 5px !important;
  font-size: 13px;
  font-family: 'appFont', sans-serif;
  padding: 10px 18px;
  width: 100%;
  &::placeholder {
    color: $light-grey;
  }



  &.invalid {
    border-color: $error-validation !important;
    color: $error-validation !important;
  }
}

input[type=text], input[type=url], input[type=password] {
  border: 1px solid $border-separator !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px $white inset;
}

.p-inputtext {
  &:enabled:focus {
    box-shadow: none !important;
    border-color: $dark-pink !important;
  }
}

textarea {
  border: 1px solid $border-separator !important;

  &::placeholder {
    color: $light-grey;
  }
}

.p-password {
  .p-input-icon-right {
    right: 18px;
  }

  eyeicon::before, eyeslashicon::before {
    content: '';
    width: 17px;
    height: 13px;
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 2px;
  }

  eyeicon {
    &::before {
      background-image: url(/assets/icons/eye.svg);
    }
  }

  eyeslashicon {
    &::before {
      background-image: url(/assets/icons/eye-close.svg);
    }
  }

  .p-icon {
    display: none;
  }
}

/* buttons */

.p-button {
  &:not(.p-datepicker-trigger) {
    .p-icon {
      display: none !important;
    }
  }

  &:focus {
    box-shadow: none !important;
  }
}


.btn-primary, .p-confirm-popup .p-confirm-popup-footer button:last-child {
  height: 29px !important;
  font-family: 'appFontBold', sans-serif !important;
  border: 1px solid $light-grey !important;
  padding: 0 23px !important;
  background-color: $error-message !important;
  color: $main-text-color !important;
  text-transform: uppercase !important;
  background-image: none !important;
  border-radius: 3px !important;

  .p-button-label {
    font-family: 'appFontBold', sans-serif !important;
  }

  &:hover, &:focus {
    background-color: $error-message-border !important;
  }
}

.btn-main {
  height: 39px !important;
  font-family: 'appFontBold', sans-serif !important;
  text-transform: uppercase;
  border: 1px solid transparent;
  //border-color: transparent !important;
  padding: 0 10px !important;
  background-image: none !important;
  border-radius: 5px !important;
  background-color: transparent;
  display: inline-block;
  text-decoration: none;
  line-height: 37px;
  text-align: center;

  span {
    font-family: 'appFontBold', sans-serif !important;
  }

  &.full {
    background-color: #FC7192 !important;

    span {
      color: white;
    }

    &:hover, &:focus {
      background-color: #F35D80 !important;
    }
  }

  &.borderd {
    border-color: $main-text-color !important;

    span {
      color: $main-text-color;
    }

    &:hover, &:focus {
      background-color: $main-text-color !important;

      span {
        color: $white !important;
      }
    }

    &.white {
      border-color: $white !important;

      span {
        color: $white;
      }

      &:hover, &:focus {
        background-color: $white !important;

        span {
          color: #FC7192 !important;
        }
      }
    }
  }

}

.p-button-rounded {
  &:hover, &:focus {
    box-shadow: none !important;
    background: transparent !important;
  }
}

.btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
  background: transparent !important;
  border-color: transparent !important;
  height: 29px;

  .p-button-label {
    text-decoration: underline;
  }
}

.btn-secondary-link {
  padding-right: 0 !important;
}

.input-with-btn {
  input {
    border-radius: 4px 0 0 4px !important;
  }

  .p-button-sm {
    background: $border-element;
    border: none;
    border-radius: 0 4px 4px 0;
    height: 34px;
    color: $main-text-color;
    font-family: 'appFontBold', sans-serif;
    font-size: 12px;
  }
}

.login-btn-secondary {
  background: $dark-green;
  border: none;
  margin-top: 10px;
  height: 47px;
  width: 100%;

  &[loading="true"] {
    cursor: not-allowed;
  }

  .lds-ellipsis {
    margin: 3px auto 0;
  }

  .p-button-label, label {
    letter-spacing: 3.35px;
    color: $white;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'appFontBold', sans-serif;
  }

  &:enabled:hover {
    background-color: #008A7B;
  }
}

a.login-btn-secondary {
  display: inline-block;
  text-decoration: none;
}

/* switch btn */

.p-inputswitch {
  height: 10px !important;
  width: 30px !important;
  margin-left: 18px;
  margin-top: 5px;

  &.p-inputswitch-checked {
    .p-inputswitch-slider:before {
      left: -6px !important;
    }
  }

  .p-inputswitch-slider {
    height: 12px !important;
    width: 28px !important;
    border-radius: 15px !important;
    background: $main-text-color !important;
    border: 1px solid $main-text-color;
    box-shadow: none !important;

    &::before {
      height: 18px !important;
      width: 18px !important;
      box-shadow: 0 1px 6px #00000026 !important;
      left: -5px !important;
      margin-top: -1px !important;
      top: -3px;
      background: white !important;
      border-radius: 50% !important;
    }
  }

  &.leftSelected.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $main-text-color !important;
      border: 1px solid $main-text-color !important;
    }

    &::before {
      background: white !important;
    }
  }
}

/* p-selectbutton */
.p-selectbutton .p-button {
  border-color: $border-separator !important;
  background: white !important;

  &.p-highlight {
    background: $dark-pink !important;
  }
}

/* radio btn */

p-radiobutton {
  cursor: pointer;
}

.p-radiobutton {
  width: 14px !important;
  height: 14px !important;
}

.p-radiobutton .p-radiobutton-box {
  width: 14px !important;
  height: 14px !important;
  border: 1px solid $border-separator !important;
  box-shadow: none !important;

  .p-radiobutton-icon {
    width: 8px !important;
    height: 8px !important;
  }

  &.p-highlight {
    border-color: #7D3261 !important;
    background: #7D3261 !important;
  }
}

.p-radiobutton-label {
  margin-left: 9px;
  cursor: pointer;
}

/* button round */

.btn-round-box:hover .btn-round {
  //width: 62px;
  //height: 62px;
  //margin-top: 4px;
  //margin-left: 4px;
  cursor: pointer;
  //-moz-transform:translate(0,0);
  //-o-transform:translate(0,0.0);
  //-webkit-transform:translate(0,0);
}

.btn-round-box:hover .btn-round span {
  color: white;
  //font-size: 40px!important;
}

.btn-round-container {
  width: 68px;
  position: absolute;
}

.btn-round {
  width: 68px;
  height: 68px;
  opacity: 1;
  border-radius: 50px;
  vertical-align: middle;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}

.btn-round:focus {
  outline: none !important;
}

.btn-round span {
  font-size: 45px !important;
  position: relative;
  top: -1px;
  left: 1px;
}

.btn-round-box label {
  font-size: 13px;
  margin-left: 1px;
  padding-left: 77px;
  padding-top: 28px;
}

/* social buttons - facebook\google desktop view */
.social-button {
  height: 40px;
  width: 200px;
  padding-left: 11px;
  padding-right: 5px;
  border-radius: 4px !important;

  .p-button-label {
    font-size: 13px;
    text-align: left;
    padding-left: 7px;
    color: white;
    font-weight: 400;
  }

  .pi::before {
    content: '';
    display: inline-block;
    background-size: 100%;
  }

  &:focus {
    box-shadow: none;
  }

  &.social-button-google {
    background: $google-btn-color !important;
    border-color: $google-btn-color;
    width: 209px;
    padding-left: 0;

    .pi-google {
      height: 47px;
      width: 47px;
      background: white;

      &::before {
        background-image: url('/assets/icons/google-new.svg');
        height: 19px;
        width: 19px;
        margin-top: 15px;
      }
    }

    &:enabled:hover {
      background: $google-btn-color-hover !important;
      border-color: $google-btn-color-hover;
    }

  }

  &.social-button-bing {
    background: $dark-green !important;
    border-color: green;
    width: 209px;
    padding-left: 0;

    .pi-bing {
      height: 47px;
      width: 47px;
      background: white;

      &::before {
        background-image: url('/assets/icons/microsoft-flat.svg');
        height: 19px;
        width: 19px;
        margin-top: 15px;
      }
    }

    &:enabled:hover {
      background: $mid-green !important;
      border-color: green;
    }
  }

  &.social-button-facebook {
    background: $facebook-btn-color !important;
    border-color: $facebook-btn-color;

    .pi-facebook {
      width: 22px;
      height: 22px;
      background: white;
      margin-top: 1px;
      border-radius: 1px;
      margin-right: 0;

      &:before {
        background-image: url('/assets/icons/facebook-blue-logo.svg');
        height: 22px;
        width: 22px;
      }
    }

    &:enabled:hover {
      background: $facebook-btn-color-hover !important;
      border-color: $facebook-btn-color-hover;
    }
  }
}

/* social buttons - facebook\google mobile view */

.social-button-mobile {
  height: 65px;
  position: relative;
  margin-left: 70px;

  p-button:first-child {
    padding-right: 12px !important;
    text-align: right;

    button {
      margin: 0;
    }
  }

  p-button:last-child {
    padding-left: 12px !important;
  }

  .social-button {
    width: 41px;
    height: 41px;
    display: inline-block;
    background-color: white !important;
    box-shadow: 0 3px 6px #00000029 !important;
    border-radius: 5px;
    border: none;
    margin-right: 23px;
    position: relative;
    transition-timing-function: ease-out;
    transition-duration: 0.2s;
    will-change: transform;

    .p-button-label {
      display: none;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      -webkit-backface-visibility: hidden;
      transform: scale(1.07, 1.07);
      background-color: white !important;
    }

    &.social-button-facebook {
      .pi-facebook {
        width: 37px;
        height: 100%;
        margin-top: 4px;
        margin-left: -13px;
        background: white;
        border-radius: 1px;

        &::before {
          background-image: url('/assets/icons/facebook-blue-logo.svg');
          height: 100%;
          background-repeat: no-repeat;
          width: 100%;
          background-size: cover;
          background-position: center;
        }
      }
    }

    &.social-button-google .pi-google {
      margin-top: -7px;
      margin-left: 12px;
      width: 35px;
      height: 36px;

      &::before {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

/* check box */

.field-checkbox label {
  cursor: pointer;
  margin-left: 9px !important;
  line-height: 0 !important;

  &.title-row {
    margin: 0 !important;
  }
}

.p-checkbox {
  width: 14px !important;
  height: 14px !important;
}

.p-checkbox .p-checkbox-box, .ui-chkbox-box {
  border: 1px solid $border-separator !important;
  border-radius: 2px !important;
  outline: none;
  width: 14px;
  height: 14px;
}

.p-checkbox .p-checkbox-box.ui-state-focus, .p-checkbox-box.ui-state-focus, .p-checkbox .p-checkbox-box:hover {
  border-color: $border-separator !important;
}

.p-checkbox .p-checkbox-box .pi-check:before, .p-checkbox-box .pi-check:before, .p-checkbox checkicon::before {
  content: "";
  background-image: url('/assets/icons/checkbox-vi-white.svg');
  width: 11px;
  height: 11px;
  margin-left: 1px;
  display: inline-block;
  background-repeat: no-repeat;
  margin-top: 3px;
  background-size: 95%;
}

.p-checkbox checkicon {
  .p-icon {
    display: none;
  }
}

.p-checkbox ~ .p-checkbox-label, .p-checkbox-label {
  margin-left: 9px !important;
  line-height: 0 !important;
  cursor: pointer;
}

.p-checkbox .p-checkbox-box.p-highlight, .p-checkbox .p-checkbox-box.p-highlight:hover, .p-checkbox .p-checkbox-box.p-highlight:focus {
  box-shadow: none !important;
}

.p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox.pink .p-checkbox-box.p-highlight:focus {
  background: $dark-pink !important;
  border-color: $dark-pink !important;
}

.p-checkbox-box.p-focus {
  box-shadow: none !important;
}

/* slide */

.p-slider .p-slider-handle {
  cursor: pointer !important;
}

/* dialog */

.p-dialog {
  border-radius: 8px !important;
  border: 1px solid $border-separator !important;

  .p-dialog-header {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    background: $main-background-color !important;
    padding-left: 32px !important;
    padding-top: 17px !important;
    border: none !important;
    height: 48px;

    .p-dialog-title {
      font-family: 'appFontBold', sans-serif;
      font-size: 13px !important;
      color: #4e4e4eb3;
      text-transform: uppercase;
      font-weight: normal !important;
    }

    .p-dialog-header-icon {
      box-shadow: none !important;
    }
  }

  .p-dialog-content {
    border-radius: 0 0 8px 8px;
    padding: 22px 32px 22px 32px !important;
    background: $main-background-color !important;
    color: $main-text-color !important;
    border-top: 1px solid #E2E3E7 !important;

    &.align-top {
      align-items: normal;
      padding-top: 9px;
    }

    .title-row {
      text-align: left !important;
    }
  }
  &.white-bg-floating-label {
    .p-dialog-header, .p-dialog-content {
      background: white !important;
    }
    .p-field {
      label {
        display: block;
        margin-bottom: 0.5em;
        color: $main-text-color;
        padding-left: 5px;
        padding-right: 5px;
      }
      .p-float-label label {
        padding-left: 5px;
        padding-right: 5px;
      }
      .p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
        top: 2px;
        background: white;
      }
    }
    .p-dropdown.p-focus:not(.p-disabled):not(.p-dropdown-open), .p-inputtext:enabled:focus {
      border: 1px solid $main-text-color!important;
    }
  }
}

.p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container{
  box-shadow: none !important;
}

.align-tags-dialog {
  //width: 458px;
  aux-emails-box {
    .title-row {
        width: 107px!important;
        padding-right: 16px!important;
        padding-left: 0!important;
        top: 0!important;
    }
    .input-row {
        width: calc(100% - 107px)!important;
        outline: none!important;
        box-shadow: none!important;
        .p-autocomplete, .input-row .p-inputnumber {
          max-width: 285px;
          outline: none!important;
          box-shadow: none!important;
        }
    }
    .tags {
      outline: none!important;
      box-shadow: none!important;
    }
    .tags .p-autocomplete-multiple-container {
        max-width: 285px;
        min-width: 285px;
        min-height: 84px;
        max-height: 84px;
        cursor: pointer;
        overflow: auto;
        align-items: normal;
        outline: none!important;
        box-shadow: none!important;
        li {
            &.p-autocomplete-input-token {
                height: 26px;
                input {
                    height: 100%;
                }
            }
            &:first-child {
                &.p-autocomplete-input-token {
                    margin-top: 6px;
                }
            }
            &.p-autocomplete-token {
                &:last-child {
                    margin-bottom: 0!important;
                }
                & ~ .p-autocomplete-input-token {
                    margin-top: 0;
                }
            }
        }
    }
}
}

.quick-wins-dialog {
  width: 460px;
  .p-dialog-content {
    line-height: 23px;
    p {
      margin: 0;
      b {
        font-family: 'appFontBold', sans-serif;
        font-weight: normal;
      }
    }
    ol {
      padding-left: 14px!important;
    }
  }
  .main-form-action-btn {
    padding-top: 19px;
    padding-bottom: 5px;
    margin-left: 0;
    .p-button.p-button-link {
      margin-left: 15px;
    }
  }
}

.add-rule-dialog {
  min-width: 806px;
  width: fit-content;
  .p-dialog-content {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
    padding-left: 36px !important;

    input, .p-dropdown, .p-treeselect {
      height: 34px;
      border: 1px solid $border-separator;
    }

    input[type=text] {
      padding-left: 14px;
      padding-right: 14px;
    }

    .role-title {
      font-family: 'appFontBold', sans-serif;

      &:not(.main-title) {
        margin-left: 14px;
        margin-right: 11px;
      }

      &.main-title {
        width: 73px;
        min-width: 73px;
        text-align: right;
        margin-right: 14px !important;
      }
    }

    .rule-col, .p-treeselect {
      width: 155px;

      &.s-size {
        width: 72px;
      }
    }

    .p-treeselect {
      .p-icon-wrapper {
        background-image: url(/assets/icons/dropdown-arrow-black.svg);
        display: inline-block;
        background-repeat: no-repeat;
        width: 9px;
        height: 6px;
        background-size: 100%;

        .p-icon {
          display: none;
        }
      }
    }

    .main-manipulationsa {
      .types-input {
        width: 115px;
      }
    }

    .action-manipulations {
      .p-treeselect {
        box-shadow: none !important;

        .p-treeselect-label-container {
          padding-left: 14px;
        }

        .p-treeselect-label {
          padding-left: 0;
          padding-right: 0;
          height: auto;
          padding-top: 9px;
        }
      }
    }

    .filter-namipulations {
      .trash-btn {
        padding: 0 !important;
        width: auto;
        height: auto;
      }

      .trash-icon {
        height: 18px !important;
      }

      .plus-btn {
        margin-left: 6px;
        width: 19px;
        height: 19px;
        border: 1px solid #012E28;
        background: $light-darkest-green !important;
        padding: 0;

        .pi.pi-plus {
          position: relative;
          top: -1px;

          &::before {
            font-size: 8px;
            font-weight: 700;
          }
        }
      }
    }

    .text-manipulations, .simple-manipulations, .feed-manipulations, .number-manipulations {
      input[type=text], .p-inputnumber .p-inputnumber-input, .p-dropdown {
        width: 155px;

        &.s-size {
          width: 72px;
        }

        &.m-size {
          width: 110px;
        }
      }

      .p-inputnumber {
        &.s-size {
          input {
            width: 72px;
          }
        }

        &.m-size {
          input {
            width: 110px;
          }
        }
      }

      text-area {
        width: 115px;
      }
    }
  }
}

.edit-feed-dialog {
  width: 566px;

  .p-dialog-content {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
    padding-left: 36px !important;

    .field {
      margin-bottom: 19px;
    }
  }

  .input-row.with-info {
    width: 284px;
  }

  .title-row {
    width: 117px !important;
    text-align: right !important;
  }

  .edit-feed-container {
    min-width: 0 !important;
  }
}

.publish-export-dialog {
  .p-dialog-content {
    padding-left: 0 !important;

    .publish-container {
      height: 100%;

      .title-row.every {
        padding-left: 11px;
      }

      & > div {
        width: 215px !important;
        padding-bottom: 0;
      }

      .col {
        > .grid:nth-child(1) {
          max-height: calc(100% - 23px);
          overflow-y: hidden;
        }

        > .grid:nth-child(2) {
          .col {
            padding-bottom: 0;
          }
        }
      }

      .p-autocomplete-multiple-container {
        overflow-x: auto;
      }

      .dropdown-show {
        width: 100% !important;
        //max-width: 214px;
      }

      .p-calendar {
        input {
          width: 100% !important;
        }
      }

      .p-inputgroup > .p-element + .p-inputgroup-addon {
        padding: 11px 0 0 12px;
      }

      .p-inputgroup-addon:first-child {
        padding: 11px 12px 0 0;
      }
    }

    .publish-action {
      line-height: 46px !important;
      height: 46px;

      div:first-child {
        padding-left: 32px;
      }

      .arrow-right {
        margin-right: 11px;
      }

      .pi {
        padding-top: 16px;
        vertical-align: top !important;
      }

      .google-merchant-icon {
        //height: 25px!important;
      }
    }

    p-divider {
      margin-left: -24px;
    }
  }

  .secondary-left-menu {
    border-bottom: none !important;
  }

  .footer-buttons {
    position: absolute;
    top: 80%;
  }
}

.eran-points-dialog {
  width: 684px;
  height: 721px;

  .p-dialog-header {
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    background: white !important;
    text-align: center;
    padding-top: 34px !important;
    height: auto;

    .p-dialog-title {
      font-size: 17px !important;
      width: 100%;
    }

    .p-dialog-header-icons {
      position: relative;
      top: -20px;
      right: 1px;
    }
  }

  .p-dialog-content {
    text-align: center;
    border-top: none !important;
    padding: 0 !important;
    border-radius: 0 0 5px 5px !important;
    background: white !important;
  }

  .p-button-link:enabled:hover {
    opacity: 0.9;
  }
}

.video-player-dialog {
  border: none !important;
  width: 1000px;
  height: 627px;

  .p-dialog-header {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    height: 63px;
    padding-left: 37px !important;
    padding-top: 23px !important;
    background: #FFF4EE !important;
    padding-right: 35px;

    .p-dialog-title {
      font-size: 14px !important;
      font-family: 'appFont', sans-serif;
    }

    .p-dialog-header-icon {
      width: auto;
      box-shadow: none !important;
    }

    .p-dialog-header-close-icon {
      background-image: url('/assets/icons/close-dark.svg');
      width: 13px;
      height: 13px;
      display: inline-block;

      &::before {
        content: none;
      }
    }
  }

  .p-dialog-content {
    padding: 0 !important;
    border-top: 1px solid #D1C9C5 !important;

    .video-player {
      width: 100%;
      height: 100%;
    }
  }
}

.website-dialog {
  width: 505px;

  .p-dialog-content {
    padding: 22px 80px 22px 32px !important;
  }

  .title-row {
    width: 115px !important;
    padding-right: 12px !important;
    padding-left: 0;
  }

  .input-row {
    width: calc(100% - 115px);
  }

  .main-form-action-btn {
    margin-left: 0;
  }
}

.alerter-setting-dialog {
  width: 458px;

  .p-dialog-content {
    padding-top: 34px !important;
    padding-bottom: 34px !important;
  }
}

.set-goals-dialog{
  width: 400px;
  height: 370px;
}

.settings-dialog{
  width: 425px;

  .p-dialog-content {
    padding: 40px;
  }

  aux-emails-box {
    div{
      padding-top: 10px;
    }
    label{
      width: 155px !important;
    }
    div > div {
      width: 200px !important;
      p-autocomplete > div > ul{
        width: 200px !important;
      }
    }
  }
}

.scheduling-dialog{

  .p-dialog-content {
    padding-top: 20px !important;
  }

  aux-emails-box {

    label{
      width: 115px !important;
    }
    div > div {
      width: 277px !important;
      p-autocomplete > div > ul{
        width: 277px !important;
      }
    }
  }
}


.alerter-dashboard-dialog {
  width: 323px !important;

  .p-dialog-content {
    padding-bottom: 35px !important;
  }

  &.p-confirm-dialog .p-dialog-footer {
    padding-bottom: 35px !important;
    padding-top: 29px !important;

    .p-button-icon {
      display: none;
    }
  }
}

.credit-card-dialog {
  width: 428px !important;

  .field {
    margin-bottom: 17px;

    &.grid > label {
      width: 120px;
      margin-bottom: 0.1rem !important;

      &[for="card-cvv"] {
        width: 57px;
        padding-right: 11px;
      }
    }
  }

  .p-inputtext {
    width: 194px;
    padding: 10px 13px;

    &:enabled:focus {
      border-color: $mid-darkest-green !important;
    }
  }

  .col-2 {
    display: inline-block !important;
    padding-top: 10px !important;
    padding-left: 18px;
    padding-right: 11px;
    width: fit-content;
  }

  .col-3 {
    margin: 0;
    padding: 0;
    width: 68px;

    .p-inputtext {
      width: 100%;
    }
  }

  .col-8 {
    margin: 0;
    padding: 0;
  }

  .buttons {
    margin-top: 28px;
  }
}

.mb-dialog-microsoft {
  width: 400px !important;;
}

.mb-dialog {
  width: 356px;

  .p-dialog-content {
    min-height: 150px;
  }

  aux-info i {
    margin-left: 15px !important;
  }

  .p-dropdown, input, .header-dropdown-details .p-dropdown {
    width: 258px;
    padding-left: 16px;
  }

  .header-dropdown-details .p-overlay {
    left: -1px;
  }

  p-inputnumber {
    width: auto;
  }

  p-inputnumber .p-inputnumber-buttons-stacked .p-inputnumber-input {
    height: 39px;
    border-color: #9A9A9A;
    padding: 10px 16px;
    width: 220px;
  }

  .p-inputnumber-buttons-stacked .p-inputnumber-button-group {
    height: 39px;

    button, button:hover {
      border-color: #9A9A9A;
    }
  }

  .header-dropdown-details {
    margin-bottom: 24px;
  }

  .loader-box {
    text-align: center;
    position: relative;
    top: 33px;

    .loader {
      div {
        width: 20px;
        height: 20px;

        &::before {
          left: -23px;
          width: 20px;
          height: 20px;
        }

        &::after {
          right: 23px;
          left: 23px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .text-center {
    .bold {
      font-family: 'appFontBold', sans-serif;
      margin-top: 10px;
      margin-bottom: 7px;
      text-transform: uppercase;
    }

    .content {
      font-size: 14px;
      line-height: 19px;
      display: inline-block;

      &.success {
        width: 230px;
      }
    }
  }
}

.select-acc-dialog:not(.p-dropdown-panel) {
  width: 362px;
}

.select-acc-dialog.items-length:not(.p-dialog) {
  width: 240px !important;
}

.secondary-menu-action-btn {
  margin-top: 17px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 20px;

  .btn-secondary-link {
    padding-left: 20px;
  }
}

.main-form-action-btn {
  margin-left: 101px;
  padding-top: 14px;

  &.attach-checkbox {
    margin-left: 25px;
  }
}

.p-confirm-dialog.plan-dialogs {
  width: 485px;

  .p-dialog-header-close {
    position: relative;
    right: -5px;
    top: 0;
  }

  .p-dialog-content {
    border-top: 1px solid transparent !important;
    padding-top: 2px !important;
    padding-left: 41px !important;
    padding-right: 41px !important;
  }

  .p-confirm-dialog-icon {
    font-size: 20px !important;
    margin-right: 11px;
  }

  .p-confirm-dialog-message {
    font-family: 'appFontBold', sans-serif;
    font-weight: normal !important;
  }

  .p-dialog-footer {
    padding: 20px 41px 53px 41px !important;

    p {
      font-size: 14px;
      line-height: 22px;
      direction: ltr !important;

      &.bold {
        font-family: 'appFontBold', sans-serif;
        font-weight: normal !important;
        padding-top: 25px;
        padding-bottom: 30px;
      }
    }

    .btn-primary {
      .p-button-label {
        text-decoration: none !important;
      }
    }
  }
}

.my-cloud-dialog {
  background: #FFFFFF !important;
  box-shadow: 0 3px 6px #0000001a;
  border: none !important;
  width: 452px;
  min-height: 252px;
  margin-top: 135px !important;

  .p-dialog-content {
    border-radius: 5px;
    border-top: none !important;
    padding: 44px !important;

    .p-image {
      width: 70px;
      height: 72px;
      background-repeat: no-repeat;
      display: inline-block;

      &.access {
        background-image: url('/assets/icons/user-access.svg');
      }

      &.no-access {
        background-image: url('/assets/icons/user-no-access.svg');
      }
    }

    .text {
      margin: 22px 0;
      text-align: center;

      h3 {
        font-weight: normal !important;
        font-family: 'appFontBold', sans-serif;
        font-size: 17px;
      }
    }
  }
}

/* confirm dialog */

.p-confirm-dialog {
  .p-dialog-content {
    border-radius: 0;
    padding-bottom: 0 !important;
  }

  .p-confirm-dialog-message {
    margin-left: 0 !important;
  }

  .p-dialog-footer {
    border-radius: 0 0 8px 8px;
    border-top: none !important;
    background: $main-background-color !important;
    text-align: left !important;
    color: $main-text-color !important;
    padding: 32px !important;
    direction: rtl;

    .p-button {
      direction: ltr;
      height: 29px !important;
      margin: 0 !important;

      &:not(.p-confirm-dialog-accept):not(.btn-primary) {
        .p-button-label {
          text-decoration: underline;
        }

        .p-button-icon {
          display: none;
        }

        &:hover {
          background: transparent !important;
        }
      }

      &.p-confirm-dialog-accept {
        border-color: #A6A6A6 !important;
        border-width: 1px;
        padding: 0 23px !important;
        text-transform: uppercase !important;
        background-image: none !important;
        border-radius: 5px !important;

        .p-button-label {
          color: $main-text-color !important;
          font-family: 'appFontBold', sans-serif;
        }

        .pi:before {
          color: $main-text-color;
          font-size: 15px;
        }
      }

      &.p-confirm-dialog-reject {
        background-color: transparent;
        border: none;
      }
    }
  }
}


/* confirm popup - version cancel event */

.p-confirm-popup {
  .p-confirm-popup-content {
    padding-bottom: 10px !important;
  }

  .p-confirm-popup-footer {
    padding-bottom: 13px !important;
    text-align: left !important;

    button:last-child {
      padding: 0 14px !important;
      float: left;

      &:hover, &:focus {
        padding: 0 13px !important;
      }
    }

    .p-confirm-popup-accept {
      &.green-palette {
        background: $light-green !important;

        &:hover, &:focus {
          background-color: $light-green !important;
        }
      }

      &.blue-palette {
        background: $light-blue !important;

        &:hover, &:focus {
          background-color: $light-blue !important;
        }
      }

      &.orange-palette {
        background: $light-orange !important;

        &:hover, &:focus {
          background-color: $light-orange !important;
        }
      }
    }
  }
}

.add-credit-card-confirm {
  .p-confirm-popup-footer {
    padding-left: 61px;
    padding-bottom: 1.25rem !important;

    button:last-child {
      width: 60px;
    }
  }
}

/* p-toast */

.p-toast .p-toast-message {
  width: 417px;
  min-height: 93px;
  max-height: 350px;
  height: auto;

  .p-toast-summary {
    font-family: 'appFontBold', sans-serif;
    font-size: 16px;
    font-weight: normal !important;
    width: 285px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .p-toast-detail {
    font-size: 15px;
    width: 285px;
    margin-top: 6px;
    white-space: initial !important;
    overflow: initial !important;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .p-toast-message-content {
    padding: 26px !important;
  }

  .p-toast-message-icon {
    margin-right: 10px;

    .p-icon {
      display: none !important;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 34px;
      height: 41px;
      background-size: 100%;
    }
  }

  .p-toast-icon-close {
    top: -16px;
    right: -18px;

    .p-toast-icon-close-icon {
      color: $main-text-color;

      &::before {
        content: '';
        background-image: url('/assets/icons/message-close.svg');
        display: inline-block;
        width: 14px;
        height: 14px;
        background-size: 100%;
      }
    }

    &:hover, &:focus {
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  &.p-toast-message-error, &.p-toast-message-warn {
    //height: auto!important;
    .p-toast-detail {
      // white-space: initial!important;
      // overflow: initial!important;
      // word-wrap: break-word;
      // overflow-wrap: break-word;
    }
  }

  &.p-toast-message-error, &.p-toast-message-warn {
    background: $error-message !important;
    border-color: $error-message-border !important;
  }

  &.p-toast-message-error .p-toast-message-icon::before {
    background-image: url('/assets/icons/message-error-red.svg');
  }

  &.p-toast-message-warn .p-toast-message-icon::before {
    background-image: url('/assets/icons/message-warn-red.svg');
  }

  &.p-toast-message-info .p-toast-message-icon::before {
    width: 38px;
    height: 40px;
  }

  &.p-toast-message-info .p-toast-message-icon {
    margin-top: -3px;

    &::before {
      color: #ffffff;
      font-size: 5px;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      position: relative;
      text-indent: -9999em;
      -webkit-animation: load4 1.3s infinite linear;
      animation: load4 1.3s infinite linear;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      margin-left: 18px;
      margin-right: 18px;
    }
  }
}


/* time-period-panel */

.time-period-panel .p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: $main-text-color;
}

/* select */

select {
  outline: none !important;
  display: inline-block !important;
  border: 1px solid $border-element !important;
  border-radius: 5px !important;;
  color: $main-text-color !important;
  background: #FFFFFF url(/assets/icons/dropdown-arrow-black.svg) no-repeat calc(100% - 14px) 14px !important;
  height: 34px !important;
  font-family: 'appFont', sans-serif !important;
  padding: 0 24px 0 14px !important;
  font-size: 13px !important;
  -moz-appearance: window;
  -webkit-appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* p-dropdown */

.invalid .p-dropdown {
  border-color: $error-validation !important;
  color: $error-validation !important;
}

.p-dropdown {
  height: 34px !important;
  border: 1px solid $border-separator !important;
  border-radius: 4px !important;
  padding: 0 14px;

  &.p-dropdown-clearable .p-dropdown-label {
    padding-right: 20px;
  }

  .p-dropdown-label.p-placeholder.p-inputtext {
    color: $light-grey !important;
  }

  .p-dropdown-clear-icon {
    right: 31px;
    top: 15px;

    &::before {
      font-size: 11px;
    }
  }

  &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
    border: 1px solid $dark-pink !important;
  }

  &:not(.p-disabled).p-focus {
    box-shadow: none !important;
  }

  &.p-dropdown-open {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .p-dropdown-trigger {
    width: 10px !important;

    .p-dropdown-trigger-icon, chevrondownicon {
      //margin-top: -6px !important;

      &::before {
        content: url('/assets/icons/dropdown-arrow-black.svg');
      }

      .p-dropdown-trigger-icon.p-icon {
        display: none;
      }
    }
  }

  .p-dropdown-label {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    padding-top: 9px;

    div {
      padding-right: 10px;
      display: inline;
    }
  }
}

.header-dropdown-details {
  .title {
    letter-spacing: 0.45px;
    text-transform: uppercase;
    font-size: 9px;
    font-family: 'appFontBold', sans-serif;
    margin-bottom: 4px;
    padding-top: 0;
  }

  .p-dropdown {
    width: 191px;
    height: 24px !important;
    border: 1px solid $bg-light-nude !important;
    padding: 0 8px 0 10px;

    .p-dropdown-label {
      border-radius: 4px !important;
      padding: 4px 11px 0 0;
    }

    &.p-dropdown-open {
      .p-dropdown-label {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
}

.p-dropdown-panel {
  border: 1px solid $border-separator !important;
  border-radius: 0 0 4px 4px !important;
  //border-top: none;
  margin-top: -1px;
}

.p-dropdown-panel {
  .p-dropdown-items {
    padding: 5px 0;
  }

  .p-dropdown-item {
    min-height: 31px;
    color: $main-text-color !important;
    padding: 5px 7px !important;
    padding-left: 15px !important;
    line-height: 20px;
    position: relative;

    &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):not(.pi):hover {
      background: $light-pink !important;
    }
  }
}

.p-dropdown-panel.size-m {
  width: 350px;
}

.p-dropdown-header, .p-multiselect-header, .p-listbox-header {
  background: white !important;
  border-bottom: 1px solid !important;
  padding: 0 !important;

  input {
    border: none !important;
    padding-left: 35px;
  }

  .pi-search {
    left: 17px;
    display: inline-table;
  }

  .pi-times {
    margin-top: -2px;

    &:before {
      font-size: 13px;
    }
  }
}

/* p-multiselect */

.p-multiselect {
  border: 1px solid $border-separator !important;
  border-radius: 4px !important;
  padding: 0 14px;
  height: 34px;
  width: 200px;

  .p-multiselect-label {
    padding-left: 0;
  }

  &.p-focus {
    box-shadow: none !important;
  }

  &.p-multiselect-open {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .p-multiselect-label {
    color: $main-text-color;
  }
  .p-multiselect-label.p-placeholder {
    color: $light-grey !important;
  }

  &.dropdown-show {

    .p-multiselect-trigger {
      width: auto;
    }

    .p-multiselect-label {
      height: 100%;
      padding-top: 10px;
    }
  }

  chevrondownicon {
    //margin-top: -6px !important;

    &::before {
      content: url('/assets/icons/dropdown-arrow-black.svg');
    }

    .p-icon {
      display: none;
    }
  }
}

.p-multiselect-panel {
  border: 1px solid $border-separator !important;
  border-radius: 0 0 4px 4px !important;
  //margin-left: -1px;
  //margin-top: -1px;
  border-top: none !important;
  width: 100%;

  .p-multiselect-items .p-multiselect-item {
    padding-left: 19px;
    box-shadow: none !important;

    &.p-highlight, &:not(.p-highlight):not(.p-disabled):hover {
      background: $light-blue !important;
    }
  }
}

/* p-listbox */

.p-listbox .p-listbox-header {
  .p-checkbox {
    margin-left: 19px;
  }

  .p-listbox-filter-icon {
    left: 4px;
  }

  .p-listbox-filter {
    padding-left: 25px;
  }
}


/* p-splitbutton */
.p-splitbutton .p-button {
  border: 1px solid $border-separator !important;

  &:first-child {
    border-right: none !important;
  }
}

/* p-autocomplete */

p-autocomplete {
  .autoComplete-with-btn-menu {
    input {
      border-radius: 5px 0 0 5px !important;
      border-right: none !important;
    }

    spinnericon {
      display: none;
    }

    .p-autocomplete-dropdown {
      background: #FFFFFF !important;
      border: 1px solid $border-separator !important;
      border-left: none !important;

      input {
        padding-left: 14px;
      }

      .p-dropdown-trigger-icon, chevrondownicon {
        //margin-top: -6px !important;

        &::before {
          content: url('/assets/icons/dropdown-arrow-black.svg');
        }

        .p-icon {
          display: none;
        }
      }
    }

    .pi {
      margin-top: -4px;
    }

    .pi-chevron-down:before {
      content: url('/assets/icons/dropdown-arrow-black.svg');
    }
  }

  .input {
    padding: 0 14px;
  }

  .p-inputwtapper-focus {
    input {
      border-right: none !important;
    }

    .p-autocomplete-dropdown {
      border-color: red;
    }
  }
}

.p-autocomplete-panel {
  .p-autocomplete-items .p-autocomplete-item {
    counter-reset: $main-text-color;
    padding-left: 14px;
    &.p-highlight {
      background: transparent;
    }
  }
}

.rule-status-dropdown-panel {
  width: 100px;

  .p-dropdown-item {
    padding-top: 7px !important;
    padding-bottom: 0 !important;
  }
}

/* p-inputnumber */

p-inputnumber {
  width: 100%;

  .p-inputnumber-button-group {
    height: 34px;

    button {
      background: transparent;
      border-left: none;
      border-color: $border-separator;

      &:hover {
        background: transparent !important;
        border-color: $border-separator !important;
      }

      &.p-inputnumber-button {
        height: 12px;

        angleupicon, angledownicon {
          &::before {
            content: '';
            background-image: url('/assets/icons/dropdown-arrow-black.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            width: 9px;
            height: 9px;
            display: inline-block;
          }
        }

        &:hover {
          border-color: $border-separator;
        }

        angleupicon {
          &::before {
            transform: rotate(180deg);
          }
        }

        .p-icon {
          display: none;
        }

        &.p-inputnumber-button-up {
          border-bottom: none;
        }

        &.p-inputnumber-button-down {
          border-top: none;
        }
      }
    }
  }

  .p-inputnumber-buttons-stacked .p-inputnumber-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none;
  }

  .p-inputtext:enabled:focus + span {
    button, button:hover {
      border-color: $dark-pink !important;
    }
  }
}

/* tags */

.tags {
  input {
    border: none !important;
    padding: 0 !important;
    width: 110px !important;
  }

  .p-autocomplete-multiple-container {
    height: auto;
    min-height: 42px;
    padding: 1px 14px;
    border: 1px solid $border-separator !important;

    &.p-focus {
      box-shadow: none !important;
    }

    .p-autocomplete-input-token {
      padding: 0;
      width: 100%;

      input {
        font-family: 'appFont', sans-serif !important;
        font-size: 13px !important;
      }
    }

    .p-autocomplete-token {
      background: $bg-light-nude !important;
      padding: 0 5px;
      height: 26px;
      line-height: 25px;
      margin: 6px 6px 2px 2px !important;
      border-radius: 4px;
    }
  }

}

.policy-tags {
  display: inline-block;
  vertical-align: top;
  margin-left: 39px;
  margin-top: -9px;
  margin-bottom: -12px;
  max-width: 580px;
  min-width: 250px;
  width: calc(100% - 153px);

  .tag {
    height: 32px;
    display: inline-block;
    border-radius: 18px;
    background: $light-turkiz;
    padding: 0 16px;
    line-height: 32px;
    margin-bottom: 12px;
    margin-right: 16px;
    cursor: pointer;

    &.selected {
      background: $mid-turkiz;
    }
  }
}

/* .p-calendar */

.p-calendar-w-btn {
  .p-inputtext {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.p-calendar {
  .p-button.p-datepicker-trigger {
    background: $dark-pink !important;
    border-color: $dark-pink !important;
  }

  table td > span.p-highlight {
    background: $dark-pink;
  }

  .p-datepicker {
    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background: $light-pink;
    }

    .p-datepicker-header {
      .p-datepicker-next:focus, .p-datepicker-prev:focus {
        box-shadow: none;
      }

      .p-datepicker-year, .p-datepicker-month {
        &:hover {
          color: $dark-pink;
        }
      }
    }

    .p-monthpicker .p-monthpicker-month, .p-yearpicker .p-yearpicker-year {
      box-shadow: none;

      &.p-highlight {
        background: $dark-pink !important;
      }

      &:hover {
        background: $light-pink;
      }
    }
  }
}

.p-calendar-w-arrow {
  input[type=text] {
    border: 1px solid $bg-light-nude !important;
    height: 24px !important;
    padding-left: 11px;
    cursor: pointer;
  }

  &::before {
    content: '';
    background-image: url('/assets/icons/dropdown-arrow-black.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 9px;
    height: 9px;
    display: inline-block;
    position: absolute;
    right: 8px;
    top: 10px;

  }
}

/* side menu link */

.title-row {
  width: 100px !important;
  margin-bottom: 0 !important;
  text-align: right !important;
  padding-right: 12px !important;
  padding-left: 3px;
  display: inline-block !important;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  position: relative;
  top: -3px;

  &.title-textarea {
    display: inline-block !important;
    padding-top: 15px !important;
    vertical-align: top !important;
  }
}

.input-row {
  width: calc(100% - 100px);
  display: inline-block;
  padding: 0;

  &.with-info {
    width: calc(100% - 124px);
  }

  &.with-button {
    width: calc(100% - 225px);
  }

  .p-multiselect, .p-dropdown, .p-autocomplete, .p-inputnumber {
    width: 100% !important;
  }
}

.info-row {
  display: inline-block;
  max-width: 20px;
  padding: 0;
  padding-top: 11px !important;
  vertical-align: top;

  i.tooltip {
    margin-left: 13px !important;
  }
}

.button-row {
  display: inline-block;
  padding: 0;
  vertical-align: middle;
  margin-left: 15px;
}


.field.bold-field-text {
  margin-top: -6px !important;

  label {
    font-family: 'appFontBold', sans-serif;
    font-weight: normal !important;
    width: auto;
  }
}

/* generic icons */

.pi.pi-search::before {
  content: '';
  background-image: url('/assets/icons/zoom-grey.svg');
  display: inline-block;
  //display: block;
  background-size: 100%;
  width: 13px;
  height: 13px;
}

/* icons */

.trash-icon {
  background-image: url('/assets/icons/trash-modal.svg');
  background-repeat: no-repeat;
  width: 13px;
  height: 17px;
  background-size: 100%;
  display: inline-block;
}

.media-footer-btn {
  padding: 15px 32px 23px 32px;
}

.p-button.p-datepicker-trigger .p-icon {
  color: $main-text-color;
}


/* tour steps
.tour-steps-panel {
  border-width: 1px!important;
  margin-top: 14px!important;
  z-index: 151!important;
  .p-overlaypanel-content {
    border-radius: 5px;
    padding: 22px 15px 12px 17px;
    min-width: 220px;
  }
  border-radius: 5px!important;
  .content {
    font-size: 16px;
    font-family: 'appFontBold';
    padding-bottom: 10px;
  }
  .justify-content-between {
    font-size: 12px;
  }
  .p-button-link {
    margin-top: -2px;
    text-decoration: none!important;
    .p-button-label {
      text-transform: uppercase;
      font-size: 12px;
      font-family: 'appFontBold';
    }
  }
  &::before {
    margin-left: 12px!important;
    border-left: 10px solid transparent!important;
    border-right: 10px solid transparent!important;
    border-bottom: 14px solid black!important;
    //border-width: n;
  }
  &::after {
    margin-left: 13px!important;
    border-left: 9px solid transparent!important;
    border-right: 9px solid transparent!important;
    border-bottom: 12px solid black!important;
  }
  .col-6.text-right {
    padding-right: 6px;
    .p-button-link {
      border-radius: 0!important;
    }
  }
  .p-overlaypanel-close {
    width: auto;
    padding-right: 13px;
    .pi-times {
      margin-top: 2px;
      &::before {
        font-size: 12px;
      }
    }
  }
}
*/


/* loader */

.loader {
  display: inline-block;
  position: relative;
  top: -25px;

  &.next-to-btn {
    top: -33px;
    left: 15px;
  }

  div {
    transform: none !important;
  }
}

.p-autocomplete-loader {
  margin-right: 10px;
  margin-top: 15px !important;
  top: -25px !important;
}

.pi-spin.p-autocomplete-loader {
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader div,
.loader div:before,
.loader div:after,
.p-autocomplete-loader,
.p-autocomplete-loader:before,
.p-autocomplete-loader:after {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader div:after {
  animation-delay: 0s !important;
}

.loader div, .p-autocomplete-loader {
  color: red;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader div:before,
.loader div:after,
.p-autocomplete-loader:before,
.p-autocomplete-loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader div:before,
.p-autocomplete-loader:before {
  left: 11px;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader div:after,
.p-autocomplete-loader:after {
  left: -11px;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

// loader 3 dots

.loader-box-dots {
  .loader {
    display: inline-block;
    position: relative;
    top: -25px;

    div {
      color: #D1D1D1;
      width: 17px;
      height: 17px;

      &::before {
        left: 25px;
        width: 17px;
        height: 17px;
      }

      &:after {
        left: -25px;
        width: 17px;
        height: 17px;
      }
    }
  }

  &.center-content-loader {
    position: absolute;
    width: calc(100% - 237px);
    text-align: center;
    top: calc(50% - 64px);

    .loader div {
      font-size: 19px;
    }

    .ball-pulse {
      width: 23px;
      height: 23px;

      &::before {
        left: 28px;
        width: 23px;
        height: 23px;
      }

      &::after {
        left: -28px;
        width: 23px;
        height: 23px;
      }
    }
  }
}

//loader progress (main alerts info progress)

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}


// login-btn-secondary loader

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 41%;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* login */

.login-container {
  h1 {
    font-size: 35px;
    color: $main-text-color;
    font-family: 'appFontBold', sans-serif;
    font-weight: normal;
    margin-bottom: 7px !important;
    text-transform: capitalize !important;
  }

  h4 { //mobile title
    font-size: 14px;
    padding: 0 75px;
    line-height: 19px;
    font-weight: normal;
    max-width: 526px;
    display: inline-block;
  }

  &.login-mobile {
    top: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    padding: 0 22px;

    .field.fluid.mb-3 {
      display: block !important;

      input[id="firstName"] {
        margin-bottom: 13px !important;
      }
    }

    aux-phone {
      .field.fluid.mb-3 {
        display: flex !important;
      }

      input:focus:not(.invalid), .p-dropdown.p-focus {
        border-color: $mid-darkest-green !important;
        border-width: 2px !important;
      }
    }

    h1 {
      font-size: 32px !important;
      letter-spacing: -1.34px;
      margin-top: 26px;
      line-height: 39px;
    }

    label {
      margin-bottom: 10px;
      display: block;
      color: $dark-backgroung-color;
      text-align: left;
    }

    .new-user {
      font-size: 13px;
      color: #707070;

      & > p-button button {
        text-decoration: underline;
      }
    }

    .p-button.p-button-link .p-button-label {
      font-size: 13px;
    }

    input, .p-dropdown {
      height: 44px !important;
      border: 2px solid #2c2c2c1a !important;
      border-radius: 5px !important;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: transparent;
      }

      &[id="username"], &[id="password"] {
        border-color: #eaeaea !important;
      }
    }

    .p-dropdown {
      max-width: 100px;
      margin-right: 6px !important;
    }

    .login-explain {
      padding-bottom: 50px;

      small {
        text-align: center !important;
        font-size: 12px !important;
        padding: 7px 38px 0;
        color: rgba(44, 44, 44, 0.75);
        line-height: 18px !important;

        a {
          font-size: 12px;
        }
      }
    }

    .p-button-link .p-button-label, a {
      text-decoration: underline !important;
    }

    .login-btn-secondary {
      width: calc(100% - 106px);
      min-width: 240px;
      margin-top: 20px;
      height: 52px !important;

      .p-button-label {
        font-size: 14px;
        letter-spacing: 3.91px;
      }
    }

    .p-divider.p-divider-horizontal {
      margin-top: 15px !important;

      .p-divider-content {
        padding: 0 11px !important;

        span {
          color: #D5D5D5;
          font-family: 'appFontBold', sans-serif;
        }
      }

      &::before {
        border-top: 1px solid #D5D5D5 !important;
      }
    }
  }
}

/* settings merchant */

.table-action-bar {
  padding-bottom: 35px;
  padding-left: 45px;

  .select-position {
    margin-left: 25px;
    margin-right: 4px;
    vertical-align: middle;
    padding-bottom: 4px;

    select {
      width: 200px;
    }
  }
}

/* table --> table management (main table) */

.table-management {
  width: 100%;

  tr {
    height: 40px;

    td {
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: none !important;
      border-bottom: 1px solid $light-pink !important;
      padding: 0 !important;
      padding-top: 5px !important;
      padding-right: 10px !important;

      &:first-child {
        padding-left: 45px !important;
      }

      &.lowercase {
        text-transform: lowercase !important;
      }
    }
  }

  th {
    font-family: 'appFontBold', sans-serif;
    font-weight: normal !important;
    padding: 0 !important;
    border-bottom: 1px solid $light-pink !important;
    border-top: none !important;
    color: $main-text-color !important;
    font-size: 13px;
    line-height: 40px;

    &:first-child {
      padding-left: 45px !important;
    }
  }
}

/* tree paginator for settings grid */
.p-treetable, .p-datatable.p-datatable-customers, .p-table.p-datatable-customers {
  .p-treetable-tbody > tr > td .p-treetable-toggler {
    height: auto;
    margin-right: 0;
  }

  .p-treetable-scrollable-body {
    overflow-y: auto;
  }

  .p-treetable-scrollable-header, .p-table-scrollable-header {
    background: transparent;
    border-bottom: 1px solid $light-pink;
  }

  .p-treetable-thead > tr > th, .p-table-thead > tr > th {
    border-bottom: none !important;
  }

  &.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: transparent;
  }

  .p-paginator {
    border: none;
    padding: 0;
    margin: 21px 0;

    button {
      padding: 10px 18px;
      border: none;
      height: 40px;
      box-shadow: none;

      .p-paginator-icon::before {
        font-size: 13px;
        color: $main-text-color !important;
      }

      &:not(.p-disabled):not(.p-highlight):hover {
        background: #e6e6e6;
        border: none;
        color: $main-text-color !important;

        .p-paginator-icon::before {
          color: $main-text-color !important;
        }
      }
    }

    .p-paginator-page.p-highlight {
      background: #f5f5f5;
      border: none;
      color: $main-text-color;
      min-width: 47px;
      height: 40px;
    }

    .p-paginator-element svg {
      color: $main-text-color;
    }
  }

  .error {
    color: $error-validation !important;
  }

  .linked {
    color: $table-linked-text !important;
  }
}

.p-treetable {
  .p-paginator {
    border: none;
    padding: 0;
    margin: 21px 0;

    button {
      padding: 10px 18px !important;
      border: none !important;
      height: 40px !important;
      box-shadow: none !important;

      .p-paginator-icon::before {
        font-size: 13px;
        color: $main-text-color !important;
      }

      &:not(.p-disabled):not(.p-highlight):hover {
        background: #e6e6e6;
        border: none;
        color: $main-text-color !important;

        .p-paginator-icon::before {
          color: $main-text-color !important;
        }
      }

      &.p-paginator-next, &.p-paginator-prev {
        min-width: 42px;

        .p-paginator-icon {
          background-image: url('/assets/icons/arrow-dark.svg');
          display: inline-block;
          background-repeat: no-repeat;
          width: 6px;
          height: 10px;
          background-size: 100%;
        }
      }

      &.p-paginator-prev {
        .p-paginator-icon {
          transform: rotate(180deg);
        }
      }

      &.p-paginator-last, &.p-paginator-first {
        .p-paginator-icon {
          background-image: url('/assets/icons/arrow-dark-double.svg');
          display: inline-block;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          background-size: 100%;
          opacity: 0.7;
        }

        &.p-disabled {
          opacity: 0.5;
        }

        path {
          display: none;
        }
      }

      &.p-paginator-first {
        .p-paginator-icon {
          transform: rotate(180deg);
        }
      }
    }

    .p-paginator-page.p-highlight {
      background: #f5f5f5;
      border: none;
      color: $main-text-color;
      min-width: 47px;
      height: 40px;
    }

    .p-paginator-element svg {
      color: $main-text-color;
    }
  }
}

.table-colored-shadow {
  .p-paginator {
    background: #FCFCFC;
    border: none;
    margin-top: 10px;
  }

  .p-datatable-wrapper {
    box-shadow: 0 5px 10px #eeecef80;
    width: 100%;
    border: 1px solid $bg-light-nude;
  }

  .p-datatable-tbody > tr {
    border-bottom: 1px solid $bg-light-nude;
    font-size: 11px;
    &:last-child {
      border-bottom: none;
    }
  }

  .p-datatable-thead > tr > th {
    border-bottom: 1px solid $bg-light-nude;
    border-top: none;
    border-right: 1px solid $bg-light-nude;
    height: 50px;
    font-size: 11px;
    padding: 20px 25px 15px 25px;;
    font-weight: normal;
    font-family: 'appFontBold', sans-serif;
    color: $main-text-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //text-align: center;
    text-align: left;

    &:last-child {
      border-right: none;
    }

    i.tooltip {
      width: 13px;
      height: 13px;
      margin-bottom: -2px;
      margin-left: 6px !important;
    }

    &.asset {
      width: 40%;
      max-width: 400px;
      min-width: 200px;
    }
  }

  .p-datatable-tbody > tr {
    &:nth-child(even) {
      td {
        background: #ff719208;
      }
    }

    & > td {
      font-size: 11px;
      height: 50px;
      border-color: $bg-light-nude;
      padding: 15px 25px 15px 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      //text-align: center;
      text-align: left;

      &:first-child {
        text-align: left;
      }

      &.bold {
        font-family: 'appFontBold', sans-serif;
      }

      &.asset {
        width: 40%;
        max-width: 400px;
        min-width: 200px;
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }

    &:hover {
      background: white !important;
    }
  }

  .p-dropdown {
    height: 15px !important;
    border: none !important;
    padding: 0;
    background: transparent !important;

    .p-dropdown-label {
      font-family: 'appFontBold', sans-serif !important;
      min-width: 100px;
      padding: 0;
      background: transparent !important;
    }

    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: none !important;
    }
  }
}

/* status tables */
.rule-status {
  padding: 0;
  margin: 0;
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;

  &::before {
    content: '';
  }

  &.paused, &.paused:hover {
    background-image: url(/assets/icons/paused-white.svg);
    width: 13px;
    height: 13px;
    display: inline-block;
    background-size: 100%;
  }

  &.active, &.active:hover, &.enabled, &.enabled:hover {
    color: #21b52c !important;
    background: #21b52c !important;

    &::before {
      //display: none;
    }
  }

  &.deleted, &.deleted:hover {
    background-image: url(/assets/icons/delete-red-white.svg);
    width: 13px;
    height: 13px;
    display: inline-block;
    background-size: 100%;
  }
}

/* paginator */

.table-short-paginator .p-paginator, .p-paginator.short-paginator {
  height: 10px;
  background: transparent;
  display: inline-block;
  position: relative;
  float: right;
  width: fit-content;

  .p-paginator-pages .p-paginator-page {
    display: none;
  }

  .p-paginator-current {
    color: $light-grey;
    border: none;
    height: 12px;
    background-color: transparent;
    cursor: auto;
    min-width: fit-content;
    position: relative;
    top: -2px;
    padding: 0;
    margin-right: -5px;
    margin-left: -9px;
  }

  button {
    box-shadow: none;
  }

  .p-paginator-prev {
    position: inherit;
    right: 100%;
    margin-top: 1px;
  }

  .p-paginator-next {
    left: -4px;
  }

  .p-paginator-next, .p-paginator-prev {
    height: 12px;
    width: 12px;
    min-width: 12px;
    background-color: transparent !important;
    border: none;

    &.p-disabled {
      opacity: 1;
    }
  }

  .pi-angle-right {
    margin-top: -2px;
  }

  .p-paginator-icon::before {
    color: $light-grey;
    font-size: 15px;
  }
}

.table-short-paginator .p-paginator {
  margin-right: -17px;

  .p-paginator-current {
    color: $main-text-color;
  }

  .p-paginator-icon::before {
    color: $main-text-color;
  }

  .p-paginator-prev anglelefticon svg, .p-paginator-next anglerighticon svg {
    color: $main-text-color;
  }
}

/* charts */

//sidebar doughnut chart
.sidebar-chart-container {
  text-align: center;
  position: relative;
  margin-bottom: -9px;
  margin-left: -2px;

  .chart-box {
    width: 109px;
    display: inline-block;
    height: 109px;
    border-radius: 50%;
    border: 1px solid $bg-light-nude;
    background: white;
    margin-top: 15px;
  }

  p-chart[type="doughnut"] {
    & > div {
      width: 82px !important;
      height: 83px !important;
      border-radius: 50%;
      background: $main-background-color;
      display: inline-block;
      margin-top: 12px;
      margin-right: -1px;

      canvas {
        display: inline-block !important;
        margin-top: -13.5px;
        margin-left: -8px;
        height: 97px !important;
        width: 97px !important;
      }
    }
  }

  .sidebar-value {
    position: relative;
    margin-top: -55px;
    padding-bottom: 40px;
    font-size: 19px;
    color: $main-text-color;
    font-family: 'appFontBold', sans-serif;
  }
}

/* palettes */

.green-palette {
  &:not(.my-cloud) {
    .app-btn-menu .p-button-icon:before {
      background-image: url(/assets/icons/app-logo-green.svg)!important;
    }
  }

  .align-tags-dialog aux-emails-box .tags .p-autocomplete-multiple-container {
    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      border: 5px solid transparent;
      background-color: $dark-green;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $dark-green !important;
    background: $dark-green !important;
  }

  .trash-icon:hover {
    background-image: url('/assets/icons/trash-green-icon.svg');
  }

  .p-splitbutton .p-button {
    background: $light-green;
  }

  //.tour-steps-panel {
  //  border-color: $mid-green!important;
  //  background-color: $light-green!important;
  //  .p-overlaypanel-content {
  //    background: $light-green!important;
  //    .p-button-link .p-button-label {
  //      color: $dark-green!important;
  //    }
  //  }
  //  &:after {
  //    border-bottom-color: $light-green!important;
  //  }
  //  &:before{
  //    border-bottom-color: $mid-green!important;
  //  }
  //}

  &.p-multiselect-panel, .p-listbox-list-wrapper {
    .p-multiselect-items .p-multiselect-item, .p-listbox-list .p-listbox-item {
      padding-left: 19px;
      box-shadow: none !important;

      &.p-highlight, &:not(.p-highlight):not(.p-disabled):hover {
        background: $light-green !important;
      }
    }
  }

  .side-menu {
    height: calc(100% - 160px - 42px);

    li[isActive='true'], li[isFirstTime='true'], li:hover {
      background: $dark-green !important;
    }
  }

  .media-footer-btn {
    .p-button-link .p-button-label {
      color: $dark-green !important;
    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $light-green !important;
        border: 1px solid $mid-green;

        &::before {
          background: $dark-green !important;
        }
      }
    }
  }

  .p-inputtext:enabled:focus {
    border-color: $dark-green !important;
  }

  .media-search-input {
    border-color: $dark-green !important;

    &.p-inputtext:enabled:focus {
      border-color: $dark-green !important;
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-color: $dark-green !important;
  }

  .p-confirm-dialog {
    .p-confirm-dialog-accept {
      background-color: $light-green !important;

      &:hover {
        background-color: $mid-green !important;
      }
    }
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border: 1px solid $mid-green !important;
  }

  .btn-primary {
    background-color: $light-green !important;

    &:hover, &:focus {
      background-color: $mid-green !important;
    }
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover .p-button-label {
      color: $dark-green;
    }
  }

  .p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox-box.p-checkbox-checked, .p-checkbox .p-checkbox-box.p-highlight {
    background: $dark-green !important;
    border-color: $dark-green !important;
  }

  .p-slider {
    .p-slider-range {
      background: $dark-green;
    }

    .p-slider-handle, &:not(.p-disabled) .p-slider-handle:hover, .p-slider-handle:focus {
      background: $dark-green;
      border: 2px solid $dark-green;
      box-shadow: none;
    }
  }

  .dropdown-panel {
    &.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      border-color: $dark-green !important;
      background: $dark-green !important;

    }
  }

  .p-dropdown-header, .p-multiselect-header, .p-listbox-header {
    border-color: $dark-green !important;

    .p-inputtext:enabled:focus {
      border: none !important;
    }
  }

  .p-menu .p-menuitem-link:not(.p-disabled):hover, .p-menu .p-menuitem-link:focus {
    box-shadow: none !important;
    background: $light-green !important;
  }

  .help-panel {
    .p-overlaypanel-content .p-menu .p-menuitem {
      border-color: $mid-green;

      &:last-child {
        border-color: $mid-green;
      }

      .p-menuitem-text {
        color: $dark-green !important;
      }
    }
  }

  .time-period-panel {
    .p-tieredmenu {
      .p-menuitem.time-period-active {
        border-left-color: $dark-orange !important;
      }

      .p-menuitem-link {
        &:hover {
          background: $light-orange;
        }
      }
    }

    .p-calendar-w-btn .p-button {
      background: $light-green !important;
      border: 1px solid $light-green !important;
    }
  }

  &.p-autocomplete-panel {
    .p-autocomplete-items .p-autocomplete-item:hover {
      background: $light-green;
    }
    .p-autocomplete-items .p-autocomplete-item {
        &.p-highlight {
          background: $light-green;
        }
      }
  }

  .p-dropdown {
    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: 1px solid $mid-green !important;
    }
  }

  select {
    &:focus {
      border: 1px solid $mid-green !important;
    }
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):hover {
      background: $light-green !important;
    }
  }

  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $dark-green;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-green-icon.svg') !important;
      }

      .additional-menu-btn {
        background-image: url('/assets/icons/dots-menu-green-icon.svg') !important;
      }
    }
  }
  .autoComplete-with-btn-menu {
    &.p-inputwtapper-focus {
      border-color: $light-green !important;
      //background: $light-green!important;
      &:hover {
        //   background: $light-green!important;
      }
    }
  }

  .p-calendar-w-btn .p-button {
    background: $light-green !important;
    border-color: $light-green !important;
  }

  .loader div, .p-autocomplete-loader {
    color: $dark-green;
  }

  //topbar icons

  p-button[isexpended="true"] .topbar-icon-btn {
    .pi-sliders-v::before {
      background-image: url('/assets/icons/columns-green-icon.svg') !important;
    }

    .pi-panel::before {
      background-image: url('/assets/icons/panel-green-icon.svg') !important;
    }
  }

  .topbar-icon-btn:hover {
    .pi-heart-fill::before {
      background-image: url('/assets/icons/health-check-green.svg');
    }

    .pi-save::before {
      background-image: url('/assets/icons/save-green-icon.svg') !important;
    }

    .pi-sliders-v::before {
      background-image: url('/assets/icons/columns-green-icon.svg') !important;
    }

    .pi-panel::before {
      background-image: url('/assets/icons/panel-green-icon.svg') !important;
    }

    .pi-plus-circle::before {
      background-image: url('/assets/icons/enrich-green-icon.svg') !important;
    }

    .pi.pi-plus::before {
      background-image: url('/assets/icons/add-green-icon.svg') !important;
    }

    .pi-download::before {
      background-image: url('/assets/icons/export-new-green.svg') !important;
    }

    .pi-download1::before {
      background-image: url('/assets/icons/download-green.svg') !important;
    }

    .pi-refresh::before {
      background-image: url('/assets/icons/cache-green-icon.svg') !important;
    }

    .pi-cloud-download::before {
      background-image: url('/assets/icons/export-new-green.svg') !important;
    }

    .pi-plus-circle1::before {
      background-image: url('/assets/icons/add-green-circle.svg') !important;
    }
  }

  .publish-button:hover {
    .pi-cloud-download {
      &::before {
        background-image: url(/assets/icons/export-new-green.svg) !important;
      }
    }
  }

  .download-button:hover {
    .pi-download1 {
      &::before {
        background-image: url('/assets/icons/download-green.svg') !important;
      }
    }
  }

  .refresh-button:hover {
    .pi-refresh {
      &::before {
        background-image: url(/assets/icons/cache-green-icon.svg) !important;
      }
    }
  }

  aux-search {
    button:hover {
      .pi.pi-search::before {
        background-image: url('/assets/icons/search-green-icon.svg') !important;
      }
    }
  }

  .p-knob-value {
    stroke: $dark-green;
  }

  .p-toast-message {
    background: $light-green !important;
    border-color: $mid-green !important;

    &.p-toast-message-info {
      .p-toast-message-icon::before {
        color: $dark-green;
      }
    }

    &.p-toast-message-success {
      .p-toast-message-icon::before {
        background-image: url('/assets/icons/message-success-green.svg') !important;
      }
    }
  }

  .media-lists-container::-webkit-scrollbar-thumb {
    background-color: $dark-green;
  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid #1FA597;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: #1FA597;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: #1FA597;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: #1FA597;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: #1FA597;
      }
    }
  }
}

.blue-palette {
  .app-btn-menu .p-button-icon:before {
    background-image: url(/assets/icons/app-logo-blue.svg)!important;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $dark-blue !important;
    background: $dark-blue !important;
  }

  //.tour-steps-panel {
  //  border-color: $mid-blue!important;
  //  background-color: $light-blue!important;
  //  .p-overlaypanel-content {
  //    background: $light-blue!important;
  //    .p-button-link .p-button-label {
  //      color: $dark-blue!important;
  //    }
  //  }
  //  &:after {
  //    border-bottom-color: $light-blue!important;
  //  }
  //  &:before{
  //    border-bottom-color: $mid-blue!important;
  //  }
  //}

  &.p-multiselect-panel, .p-listbox-list-wrapper {
    .p-multiselect-items .p-multiselect-item, .p-listbox-list .p-listbox-item {
      padding-left: 19px;
      box-shadow: none !important;

      &.p-highlight, &:not(.p-highlight):not(.p-disabled):hover {
        background: $light-blue !important;
      }
    }
  }

  app-publish .secondary-left-menu .publish-action {
    &[isActive='true'], &:hover {
      background: $light-blue !important;
    }
  }

  .side-menu {
    height: calc(100% - 72px);

    li[isActive='true'], li:hover {
      background: white !important;
      label {
        color: $main-text-color!important;
      }
      .cockpit {
          background-image: url('/assets/icons/cockpit-icon-dark.svg')!important;
      }
      .ad {
          background-image: url('/assets/icons/ads-icon-dark.svg')!important;
      }
      .keyword {
          background-image: url('/assets/icons/keywords-icon-dark.svg')!important;
      }
    }
  }

  .media-footer-btn {
    .p-button-link .p-button-label {
      color: $dark-blue !important;
    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $light-blue !important;
        border: 1px solid $mid-blue;

        &::before {
          background: $dark-blue !important;
        }
      }
    }
  }

  .media-search-input {
    border-color: $dark-blue !important;

    &.p-inputtext:enabled:focus {
      border-color: $dark-blue !important;
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-color: $dark-blue !important;
  }

  .p-confirm-dialog {
    .p-confirm-dialog-accept {
      background-color: $light-blue !important;

      &:hover {
        background-color: $mid-blue !important;
      }
    }
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border: 1px solid $mid-blue !important;
  }

  .btn-primary {
    background-color: $light-blue !important;

    &:hover, &:focus {
      background-color: $mid-blue !important;
    }
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover .p-button-label {
      color: $dark-blue;
    }
  }

  .p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox-box.p-checkbox-checked, .p-checkbox .p-checkbox-box.p-highlight {
    background: $dark-blue !important;
    border-color: $dark-blue !important;
  }

  .p-slider {
    .p-slider-range {
      background: $dark-blue;
    }

    .p-slider-handle {
      background: $dark-blue;
      border: 2px solid $dark-blue;
    }
  }

  .p-dropdown-header, .p-multiselect-header, .p-listbox-header {
    border-color: $dark-blue !important;

    .p-inputtext:enabled:focus {
      border: none !important;
    }
  }

  .p-menu .p-menuitem-link:not(.p-disabled):hover, .p-menu .p-menuitem-link:focus {
    box-shadow: none !important;
    background: $light-blue !important;
  }

  .help-panel {
    .p-overlaypanel-content .p-menu .p-menuitem {
      border-color: $mid-blue;

      &:last-child {
        border-color: $mid-blue;
      }

      .p-menuitem-text {
        color: $dark-blue !important;
      }
    }
  }

  .time-period-panel {
    .p-tieredmenu {
      .p-menuitem.time-period-active {
        border-left-color: $dark-blue !important;
      }

      .p-menuitem-link {
        &:hover {
          background: $light-blue;
        }
      }
    }

    .p-calendar-w-btn .p-button {
      background: $light-blue !important;
      border: 1px solid $light-blue !important;
    }
  }

  .p-calendar-w-btn .p-button, .p-calendar-w-btn .p-button:enabled:hover {
    background: $light-blue;
    border: 1px solid $light-blue;
  }

  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    background: red;
  }

  &.p-autocomplete-panel {
    .p-autocomplete-items .p-autocomplete-item:hover {
      background: $light-blue;
    }
  }

  .p-dropdown {
    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: 1px solid $mid-blue !important;
    }
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):hover {
      background: $light-blue !important;
    }
  }

  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $dark-blue;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-blue-icon.svg') !important;
      }

      .additional-menu-btn {
        background-image: url('/assets/icons/dots-menu-blue-icon.svg') !important;
      }
    }
  }

  aux-time-period .p-button-label {
    border-color: $dark-blue !important;
  }

  .autoComplete-with-btn-menu {
    &.p-inputwtapper-focus {
      border-color: $light-blue !important;
      // background: $light-blue!important;
      &:hover {
        //   background: $light-blue!important;
      }
    }
  }

  .menu-btn:hover {
    .pi-ellipsis-v:before {
      background-image: url('/assets/icons/dots-menu-blue-icon.svg') !important;
    }
  }

  .loader div, .p-autocomplete-loader {
    color: $dark-blue;
  }

  //topbar icons
  .topbar-icon-btn:hover {
    .pi-heart-fill::before {
      background-image: url('/assets/icons/health-check.svg');
    }

    .pi-save::before {
      background-image: url('/assets/icons/save-blue-icon.svg') !important;
    }

    .pi-sliders-v::before {
      background-image: url('/assets/icons/columns-blue-icon.svg') !important;
    }

    .pi-plus-circle::before {
      background-image: url('/assets/icons/enrich-blue-icon.svg') !important;
    }

    .pi.pi-plus::before {
      background-image: url('/assets/icons/add-blue-icon.svg') !important;
    }

    .pi-download::before {
      background-image: url('/assets/icons/export-blue-icon.svg') !important;
    }

    .pi-download1::before {
      background-image: url('/assets/icons/download-blue.svg') !important;
    }

    .pi-chart-line::before {
      background-image: url('/assets/icons/pie-chart-blue.svg') !important;
    }

    .pi-refresh::before {
      background-image: url('/assets/icons/cache-blue-icon.svg') !important;
    }

    .pi-cloud-download::before {
      background-image: url('/assets/icons/export-blue-icon.svg') !important;
    }

    .pi-plus-circle1::before {
      background-image: url('/assets/icons/add-blue-circle.svg') !important;
    }
  }

  .publish-button:hover {
    .pi-cloud-download {
      &::before {
        background-image: url(/assets/icons/export-blue-icon.svg) !important;
      }
    }

    .pi-download1 {
      &::before {
        background-image: url(/assets/icons/export-blue-icon.svg) !important;
      }
    }
  }

  aux-search {
    button:hover {
      .pi.pi-search::before {
        background-image: url('/assets/icons/search-blue-icon.svg') !important;
      }
    }
  }

  .p-knob-value {
    stroke: $dark-blue;
  }

  .p-toast-message {
    background: $light-blue !important;
    border-color: $mid-blue !important;

    &.p-toast-message-info {
      .p-toast-message-icon::before {
        color: $dark-blue;
      }
    }

    &.p-toast-message-success {
      .p-toast-message-icon::before {
        background-image: url('/assets/icons/message-success-blue.svg') !important;
      }
    }
  }

  .media-lists-container::-webkit-scrollbar-thumb {
    background-color: $dark-blue;
  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid $dark-blue;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $dark-blue;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $dark-blue;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $dark-blue;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $dark-blue;
      }
    }
  }
}

.orange-palette {
  .app-btn-menu .p-button-icon:before {
    background-image: url(/assets/icons/app-logo-orange.svg)!important;
  }
  .iconic-with-text .icon-box {
    width: 28px !important;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $dark-orange !important;
    background: $dark-orange !important;
  }

  //.tour-steps-panel {
  //  border-color: $mid-orange!important;
  //  background-color: $light-orange!important;
  //  .p-overlaypanel-content {
  //    background: $light-orange!important;
  //    .p-button-link .p-button-label {
  //      color: $dark-orange!important;
  //    }
  //  }
  //  &:after {
  //    border-bottom-color: $light-orange!important;
  //  }
  //  &:before{
  //    border-bottom-color: $mid-orange!important;
  //  }
  //}

  &.p-multiselect-panel, .p-listbox-list-wrapper {
    .p-multiselect-items .p-multiselect-item, .p-listbox-list .p-listbox-item {
      padding-left: 19px;
      box-shadow: none !important;

      &.p-highlight, &:not(.p-highlight):not(.p-disabled):hover {
        background: $light-orange !important;
      }
    }
  }

  .side-menu {
    height: calc(100% - 28px);

    li[isActive='true'], li:hover {
      background: $dark-orange !important;
    }
  }

  .media-footer-btn {
    .p-button-link .p-button-label {
      color: $dark-orange !important;
    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $light-orange !important;
        border: 1px solid $mid-orange;

        &::before {
          background: $dark-orange !important;
        }
      }
    }
  }

  .media-search-input {
    border-color: $dark-orange !important;

    &.p-inputtext:enabled:focus {
      border-color: $dark-orange !important;
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-color: $dark-orange !important;
  }

  .p-confirm-dialog {
    .p-confirm-dialog-accept {
      background-color: $light-orange !important;

      &:hover {
        background-color: $mid-orange !important;
      }
    }
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border: 1px solid $mid-orange !important;
  }

  .btn-primary {
    background-color: $light-orange !important;

    &:hover, &:focus {
      background-color: $mid-orange !important;
    }
  }

  .btn-secondary-link > .btn-secondary-link {
    padding-left: 0 !important;
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover .p-button-label {
      color: $dark-orange;
    }
  }

  .p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox-box.p-checkbox-checked, .p-checkbox .p-checkbox-box.p-highlight {
    background: $dark-orange !important;
    border-color: $dark-orange !important;
  }

  .p-slider {
    .p-slider-range {
      background: $dark-orange;
    }

    .p-slider-handle {
      background: $dark-orange;
      border: 2px solid $dark-orange;
    }
  }

  .p-dropdown-header, .p-multiselect-header, .p-listbox-header {
    border-color: $dark-orange !important;

    .p-inputtext:enabled:focus {
      border: none !important;
    }
  }

  .p-menu .p-menuitem-link:not(.p-disabled):hover, .p-menu .p-menuitem-link:focus {
    box-shadow: none !important;
    background: $light-orange !important;
  }

  .help-panel {
    .p-overlaypanel-content .p-menu .p-menuitem {
      border-color: $mid-orange;

      &:last-child {
        border-color: $mid-orange;
      }

      .p-menuitem-text {
        color: $dark-orange !important;
      }
    }
  }

  .time-period-panel {
    .p-tieredmenu {
      .p-menuitem.time-period-active {
        border-left-color: $dark-orange !important;
      }

      .p-menuitem-link {
        &:hover {
          background: $light-orange;
        }
      }
    }

    .p-calendar-w-btn .p-button {
      background: $light-orange !important;
      border: 1px solid $light-orange !important;
    }
  }

  &.p-autocomplete-panel {
    .p-autocomplete-items .p-autocomplete-item:hover {
      background: $light-orange;
    }
  }

  .p-dropdown {
    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: 1px solid $mid-orange !important;
    }
  }

  &.p-dropdown-panel {
    .p-dropdown-items .p-dropdown-item:hover, .p-dropdown-items .p-dropdown-item:focus {
      background: $light-orange;
    }
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):hover {
      background: $light-orange !important;
    }
  }

  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $dark-orange;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-orange.svg') !important;
      }

      .additional-menu-btn {
        background-image: url('/assets/icons/dots-menu-orange-icon.svg') !important;
      }
    }
  }

  aux-time-period .p-button-label {
    border-color: $dark-orange !important;
  }

  .autoComplete-with-btn-menu {
    &.p-inputwtapper-focus {
      border-color: $light-orange !important;

      &:hover {
        //  background: $light-orange!important;
      }
    }
  }

  .menu-btn:hover {
    .pi-ellipsis-v:before {
      background-image: url('/assets/icons/dots-menu-orange-icon.svg') !important;
    }
  }

  .loader div, .p-autocomplete-loader {
    color: $dark-orange;
  }

  //topbar icons
  .topbar-icon-btn:hover {
    .pi-save::before {
      background-image: url('/assets/icons/save-orange.svg') !important;
    }

    .pi-sliders-v::before {
      background-image: url('/assets/icons/columns-orange.svg') !important;
    }

    .pi-plus-circle::before {
      background-image: url('/assets/icons/enrich-orange.svg') !important;
    }

    .pi.pi-plus::before {
      background-image: url('/assets/icons/add-orange.svg') !important;
    }

    .pi-download::before {
      background-image: url('/assets/icons/export-orange.svg') !important;
    }

    .pi-refresh::before {
      background-image: url('/assets/icons/cache-orange.svg') !important;
    }

    .pi-cloud-download::before {
      background-image: url('/assets/icons/export-orange.svg') !important;
    }
  }

  aux-search {
    button:hover {
      .pi.pi-search::before {
        background-image: url('/assets/icons/search-orange.svg') !important;
      }
    }
  }

  .p-knob-value {
    stroke: $dark-orange;
  }

  .p-toast-message {
    background: $light-orange !important;
    border-color: $mid-orange !important;

    &.p-toast-message-info {
      .p-toast-message-icon::before {
        color: $dark-orange;
      }
    }

    &.p-toast-message-success {
      .p-toast-message-icon::before {
        background-image: url('/assets/icons/message-success-blue.svg') !important;
      }
    }
  }

  .media-lists-container::-webkit-scrollbar-thumb {
    background-color: $dark-orange;
  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid $dark-orange;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $dark-orange;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $dark-orange;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $dark-orange;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $dark-orange;
      }
    }
  }
}

.purple-palette {
  .app-btn-menu .p-button-icon:before {
    background-image: url(/assets/icons/app-logo-light-blue.svg)!important;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $dark-purple !important;
    background: $dark-purple !important;
  }

  .app-btn-menu .p-button-label {
    font-size: 15px;
  }

  //.tour-steps-panel {
  //  border-color: $mid-purple!important;
  //  background-color: $light-purple!important;
  //  .p-overlaypanel-content {
  //    background: $light-purple!important;
  //    .p-button-link .p-button-label {
  //      color: $dark-purple!important;
  //    }
  //  }
  //  &:after {
  //    border-bottom-color: $light-purple!important;
  //  }
  //  &:before{
  //    border-bottom-color: $mid-purple!important;
  //  }
  //}

  &.p-multiselect-panel, .p-listbox-list-wrapper {
    .p-multiselect-items .p-multiselect-item, .p-listbox-list .p-listbox-item {
      padding-left: 19px;
      box-shadow: none !important;

      &.p-highlight, &:not(.p-highlight):not(.p-disabled):hover {
        background: $light-purple !important;
      }
    }
  }

  .side-menu {
    li[isActive='true'], li:hover {
      background: $dark-purple !important;
    }
  }

  .media-footer-btn {
    .p-button-link .p-button-label {
      color: $dark-purple !important;
    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $light-purple !important;
        border: 1px solid $mid-purple;

        &::before {
          background: $dark-purple !important;
        }
      }
    }
  }

  .p-inputtext:enabled:focus {
    border-color: $dark-purple !important;
  }

  .media-search-input {
    border-color: $dark-purple !important;

    &.p-inputtext:enabled:focus {
      border-color: $dark-purple !important;
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-color: $dark-purple !important;
  }

  .p-confirm-dialog {
    .p-confirm-dialog-accept {
      background-color: $light-purple !important;

      &:hover {
        background-color: $mid-purple !important;
      }
    }
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border: 1px solid $mid-purple !important;
  }

  .btn-primary {
    background-color: $light-purple !important;

    &:hover, &:focus {
      background-color: $mid-purple !important;
    }
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover .p-button-label {
      color: $dark-purple;
    }
  }

  .p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox-box.p-checkbox-checked, .p-checkbox .p-checkbox-box.p-highlight {
    background: $dark-purple !important;
    border-color: $dark-purple !important;
  }

  .p-slider {
    .p-slider-range {
      background: $dark-purple;
    }

    .p-slider-handle, &:not(.p-disabled) .p-slider-handle:hover, .p-slider-handle:focus {
      background: $dark-purple;
      border: 2px solid $dark-purple;
      box-shadow: none;
    }
  }

  .dropdown-panel {
    &.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      border-color: $dark-purple !important;
      background: $dark-purple !important;

    }
  }

  .p-dropdown-header, .p-multiselect-header, .p-listbox-header {
    border-color: $dark-purple !important;

    .p-inputtext:enabled:focus {
      border: none !important;
    }
  }

  .p-menu .p-menuitem-link:not(.p-disabled):hover, .p-menu .p-menuitem-link:focus {
    box-shadow: none !important;
    background: $light-purple !important;
  }

  .help-panel {
    .p-overlaypanel-content .p-menu .p-menuitem {
      border-color: $mid-purple;

      &:last-child {
        border-color: $mid-purple;
      }

      .p-menuitem-text {
        color: $dark-purple !important;
      }
    }
  }

  .time-period-panel {
    .p-tieredmenu {
      .p-menuitem.time-period-active {
        border-left-color: $dark-purple !important;
      }

      .p-menuitem-link {
        &:hover {
          background: $light-purple;
        }
      }
    }

    .p-calendar-w-btn .p-button {
      background: $light-purple !important;
      border: 1px solid $light-purple !important;
    }
  }

  &.p-autocomplete-panel {
    .p-autocomplete-items .p-autocomplete-item:hover {
      background: $light-purple;
    }
  }

  .p-dropdown {
    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: 1px solid $mid-purple !important;
    }
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):hover {
      background: $light-purple !important;
    }
  }

  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $dark-purple;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-purple-icon.svg') !important;
      }

      .additional-menu-btn {
        background-image: url('/assets/icons/dots-menu-purple-icon.svg') !important;
      }
    }
  }

  .autoComplete-with-btn-menu {
    &.p-inputwtapper-focus {
      border-color: $light-purple !important;

      &:hover {
        //   background: $light-purple!important;
      }
    }
  }

  .p-calendar-w-btn .p-button {
    background: $light-purple !important;
    border-color: $light-purple !important;
  }

  .loader div, .p-autocomplete-loader {
    color: $dark-purple;
  }

  //topbar icons
  .topbar-icon-btn:hover {
    .pi-heart-fill::before {
      background-image: url('/assets/icons/health-check-purple.svg');
    }

    .pi-save::before {
      background-image: url('/assets/icons/save-purple-icon.svg') !important;
    }

    .pi-sliders-v::before {
      background-image: url('/assets/icons/columns-purple-icon.svg') !important;
    }

    //.pi-plus-circle::before {
    //  background-image: url('/assets/icons/enrich-green-icon.svg')!important;
    //}
    .pi.pi-plus::before {
      background-image: url('/assets/icons/add-purple-icon.svg') !important;
    }

    .pi-download::before {
      background-image: url('/assets/icons/export-purple-icon.svg') !important;
    }

    .pi-download1::before {
      background-image: url('/assets/icons/download-purple.svg') !important;
    }

    .pi-refresh::before {
      background-image: url('/assets/icons/cache-purple-icon.svg') !important;
    }

    .pi-cloud-download::before {
      background-image: url('/assets/icons/export-purple-icon.svg') !important;
    }

    .pi-plus-circle1::before {
      background-image: url('/assets/icons/add-purple-circle.svg') !important;
    }
  }

  .publish-button:hover {
    .pi-cloud-download {
      &::before {
        background-image: url(/assets/icons/export-new-green.svg) !important;
      }
    }
  }

  aux-search {
    button:hover {
      .pi.pi-search::before {
        background-image: url('/assets/icons/search-purple-icon.svg') !important;
      }
    }
  }

  .p-knob-value {
    stroke: $dark-purple;
  }

  .p-toast-message {
    background: $light-purple !important;
    border-color: $mid-purple !important;

    &.p-toast-message-info {
      .p-toast-message-icon::before {
        color: $dark-purple;
      }
    }

    &.p-toast-message-success {
      .p-toast-message-icon::before {
        background-image: url('/assets/icons/message-success-blue.svg') !important;
      }
    }
  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid $dark-purple;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $dark-purple;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $dark-purple;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $dark-purple;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $dark-purple;
      }
    }
  }
}

.pink-palette {
  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $dark-pink;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-pink.svg') !important;
      }
    }
  }

  .media-footer-btn {
    .p-button-link .p-button-label {
      color: $dark-pink !important;
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-color: $dark-pink !important;
  }

  .media-search-input {
    border-color: $dark-pink !important;

    &.p-inputtext:enabled:focus {
      border-color: $dark-pink !important;
    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $light-pink !important;
        border: 1px solid $mid-pink;

        &::before {
          background: $dark-pink !important;
        }
      }
    }
  }

  .btn-round {
    border: 2px solid #FF71924D;
    background: $light-pink;
  }

  .btn-round-box:hover .btn-round {
    background: $dark-pink;
    border: 2px solid $border-separator;
  }

  .btn-round-box label {
    color: $dark-pink;
  }

  .p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox-box.p-checkbox-checked, .p-checkbox .p-checkbox-box.p-highlight {
    background: $dark-pink !important;
    border-color: $dark-pink !important;
  }

  .loader div, .p-autocomplete-loader {
    color: $dark-pink;
  }

  &.p-multiselect-panel, .p-listbox-list-wrapper {
    .p-multiselect-items .p-multiselect-item, .p-listbox-list .p-listbox-item {
      padding-left: 19px;
      box-shadow: none !important;

      &.p-highlight, &:not(.p-highlight):not(.p-disabled):hover {
        background: $light-pink !important;
      }
    }
  }

  .p-dropdown-header, .p-multiselect-header, .p-listbox-header {
    border-color: $dark-pink !important;

    .p-inputtext:enabled:focus {
      border: none !important;
    }
  }

  .p-confirm-dialog {
    .p-confirm-dialog-accept {
      background-color: $light-pink !important;

      &:hover {
        background-color: $mid-pink !important;
      }
    }

    .p-confirm-dialog-reject {
      &:hover {
        .p-button-label {
          color: $dark-pink;
        }
      }
    }
  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid $dark-pink;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $dark-pink;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $dark-pink;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $dark-pink;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $dark-pink;
      }
    }
  }

  &.alerter-header-dropdown {
    border: 1px solid $bg-light-nude !important;

    .p-dropdown-item {
      height: 34px;
      line-height: 27px;
      padding-left: 11px !important;
      padding-right: 11px !important;
    }
  }
}

.turkiz-palette {
  .app-btn-menu .p-button-icon:before {
    background-image: url(/assets/icons/app-logo-turkiz.svg)!important;
  }
  .table-colored-shadow .p-datatable-tbody > tr:nth-child(even) td {
    background: #38dcdc0d;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $dark-turkiz !important;
    background: $dark-turkiz !important;
  }

  .btn-primary {
    background-color: $light-turkiz !important;

    &:hover, &:focus {
      background-color: $mid-turkiz !important;
    }
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover .p-button-label {
      color: $dark-turkiz;
    }
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $light-turkiz !important;
        border: 1px solid $mid-turkiz;

        &::before {
          background: $dark-turkiz !important;
        }
      }
    }
  }

  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border: 1px solid $mid-turkiz !important;
  }

  .p-dropdown {
    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: 1px solid $mid-turkiz !important;
    }
  }

  &.p-dropdown-panel {
    width: 100%;

    .p-dropdown-items .p-dropdown-item {
      &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):hover {
        background: $light-turkiz !important;
      }
    }

    &.alerter-header-dropdown {
      border: 1px solid $bg-light-nude !important;
      min-width: 191px !important;

      .p-dropdown-item {
        height: 34px;
        line-height: 27px;
        padding-left: 11px !important;
        padding-right: 11px !important;
      }
    }
  }

  .time-period-panel {
    .p-tieredmenu {
      .p-menuitem.time-period-active {
        border-left-color: $dark-turkiz !important;
      }

      .p-menuitem-link {
        &:hover {
          background: $light-turkiz;
        }
      }

      .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: $light-turkiz;
      }
    }

    .p-calendar-w-btn .p-button {
      background: $light-turkiz !important;
      border: 1px solid $light-turkiz !important;
    }
  }

  .p-confirm-dialog {
    .p-confirm-dialog-accept {
      background-color: $light-turkiz !important;

      &:hover {
        background-color: $mid-turkiz !important;
      }
    }

    .p-confirm-dialog-reject {
      &:hover {
        .p-button-label {
          color: $dark-turkiz;
        }
      }
    }
  }

  .loader div, .p-autocomplete-loader {
    color: $dark-turkiz;
  }

  .p-datepicker {
    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background: $light-turkiz;
    }

    .p-datepicker-header {
      .p-datepicker-next:focus, .p-datepicker-prev:focus {
        box-shadow: none;
      }

      .p-datepicker-year, .p-datepicker-month {
        &:hover {
          color: $dark-turkiz !important;
        }
      }
    }

    .p-monthpicker .p-monthpicker-month, .p-yearpicker .p-yearpicker-year {
      box-shadow: none;
    }

    table td > span.p-highlight {
      background: $dark-turkiz !important;

      &:hover {
        background: $light-turkiz;
      }
    }
  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid $dark-turkiz;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $dark-turkiz;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $dark-turkiz;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $dark-turkiz;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $dark-turkiz;
      }
    }
  }
}

.blue-darker-palette {
  .app-btn-menu .p-button-icon:before {
    background-image: url(/assets/icons/app-logo-dark-blue.svg)!important;
  }
  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $dark-darkest-blue;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-dark-blue-icon.svg') !important;
      }

    }
  }

  .media-lists-container::-webkit-scrollbar-thumb {
    background-color: $dark-darkest-blue;
  }

  .media-search-input {
    border-color: $dark-darkest-blue !important;

    &.p-inputtext:enabled:focus {
      border-color: $dark-darkest-blue !important;
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-color: $dark-darkest-blue !important;
  }

  .media-footer-btn {
    .p-button-link .p-button-label {
      color: $dark-darkest-blue !important;
    }
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover {
      color: $dark-darkest-blue;

      .p-button-label {
        color: $dark-darkest-blue;

      }
    }
  }

  .p-inputswitch.leftSelected {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $main-text-color !important;
        border: 1px solid $main-text-color !important;

        &::before {
          background: white !important;
        }
      }
    }

    .p-inputswitch-slider {
      background: $light-darkest-blue !important;
      border: 1px solid $mid-darkest-blue !important;

      &::before {
        background: $dark-darkest-blue !important;
      }
    }
  }

  .btn-primary {
    background-color: $light-darkest-blue !important;

    &:hover, &:focus {
      background-color: $mid-darkest-blue !important;
    }
  }

  .p-toast-message {
    background: $light-darkest-blue !important;
    border-color: $mid-darkest-blue !important;

    &.p-toast-message-info {
      .p-toast-message-icon::before {
        color: $dark-darkest-blue;
      }
    }

    &.p-toast-message-success {
      .p-toast-message-icon::before {
        background-image: url('/assets/icons/message-success-blue.svg') !important;
      }
    }
  }

  &.p-dropdown-panel {
    width: calc(100% - 15px);

    .p-dropdown-items .p-dropdown-item {
      &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):hover {
        background: $light-darkest-blue !important;
      }
    }

    &.hr-header-dropdown {
      border: 1px solid $bg-light-nude !important;
      min-width: 191px !important;

      .p-dropdown-item {
        height: 34px;
        line-height: 27px;
        padding-left: 11px !important;
        padding-right: 11px !important;
      }
    }

  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid $dark-darkest-blue;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $dark-darkest-blue;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $dark-darkest-blue;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $dark-darkest-blue;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $dark-darkest-blue;
      }
    }
  }
}

//media blast
.purple-darker-palette {
  .app-btn-menu .p-button-icon:before {
    background-image: url(/assets/icons/app-logo-dark-purple.svg);
  }
  .p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox-box.p-checkbox-checked, .p-checkbox .p-checkbox-box.p-highlight {
    background: $mid-darkest-purple !important;
    border-color: $mid-darkest-purple !important;
  }

  &.alerter-header-dropdown.align .p-dropdown-items .p-dropdown-item {
    padding-left: 10px !important;
  }

  .loader div, .p-autocomplete-loader {
    color: $dark-darkest-purple;
  }

  .table-colored-shadow .p-datatable-tbody > tr:nth-child(even) td {
    background: $light-darkest-purple;
  }

  .p-dropdown {
    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: 1px solid $mid-darkest-purple !important;
    }
  }

  .p-inputtext:enabled:focus {
    border-color: $mid-darkest-purple !important;
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item, .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):hover {
      background: $light-darkest-purple !important;
    }
  }

  p-inputnumber .p-inputtext:enabled:focus + span button, p-inputnumber .p-inputtext:enabled:focus + span button:hover {
    border-color: $mid-darkest-purple !important;
  }

  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $dark-darkest-purple;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-darkest-purple-icon.svg') !important;
      }

      .additional-menu-btn {
        //background-image: url('/assets/icons/dots-menu-green-icon.svg') !important; //todo
      }
    }
  }

  .btn-primary {
    background-color: #FFDA53 !important;
    border-color: #EECC50 !important;
    border-width: 2px !important;

    &:hover, &:focus {
      background-color: #EECC50 !important;
    }
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover .p-button-label {
      color: $dark-darkest-purple;
    }
  }

  .time-period-panel {
    .p-tieredmenu {
      .p-menuitem.time-period-active {
        border-left-color: $dark-darkest-purple !important;
      }

      .p-menuitem-link {
        &:hover {
          background: $light-darkest-purple;
        }
      }

      .p-menuitem.p-menuitem-active > .p-menuitem-link {
        background: $light-darkest-purple;
      }
    }

    .p-calendar-w-btn .p-button {
      background: $light-darkest-purple !important;
      border: 1px solid $light-darkest-purple !important;
    }
  }

  &.alerter-dashboard-dialog {
    .p-button.p-confirm-dialog-accept {
      background-color: #FFDA53 !important;
      border-color: #EECC50 !important;
      border-width: 2px !important;

      &:hover {
        background-color: #EECC50 !important;
      }
    }

    .p-confirm-dialog-reject {
      &:hover .p-button-label {
        color: $dark-darkest-purple;
      }
    }

    .p-confirm-dialog-message {
      line-height: 19px;
    }
  }

  &.p-tooltip {
    .p-tooltip-text {
      border: 1px solid $dark-darkest-purple;
    }

    &.p-tooltip-top {
      .p-tooltip-arrow {
        border-top-color: $dark-darkest-purple;
      }
    }

    &.p-tooltip-bottom {
      .p-tooltip-arrow {
        border-bottom-color: $dark-darkest-purple;
      }
    }

    &.p-tooltip-left {
      .p-tooltip-arrow {
        border-left-color: $dark-darkest-purple;
      }
    }

    &.p-tooltip-right {
      .p-tooltip-arrow {
        border-right-color: $dark-darkest-purple;
      }
    }
  }
  .p-toast-message {
    background: $light-blue !important;
    border-color: $mid-blue !important;

    &.p-toast-message-info {
      .p-toast-message-icon {
        margin-top: 0;
        &::before {
          background-image: url('/assets/icons/message-alert-blue.svg') !important;
          animation: none;
          display: inline-block;
          width: 34px;
          height: 41px;
          background-size: 100%;
          border-radius: 0;
          margin: 0;
        }
      }
    }

    &.p-toast-message-success {
      .p-toast-message-icon::before {
        background-image: url('/assets/icons/message-success-blue.svg') !important;
      }
    }
  }
}

//feeditor plus
.green-darker-palette {
  .app-btn-menu .p-button-icon:before {
    background-image: url(/assets/icons/app-logo-green-darker.svg)!important;
  }
  .p-toast-message {
    background: $light-darkest-green !important;
    border-color: $mid-darkest-green !important;

    &.p-toast-message-info {
      .p-toast-message-icon::before {
        color: $dark-green;
      }
    }

    &.p-toast-message-success {
      .p-toast-message-icon::before {
        background-image: url('/assets/icons/message-success-green.svg') !important;
      }
    }
  }
  .btn-primary {
    background-color: $light-darkest-green !important;

    &:hover, &:focus {
      background-color: #c6e8e5cc !important;
    }
  }

  .btn-secondary-link, .p-confirm-popup .p-confirm-popup-footer button:first-child {
    &:hover .p-button-label {
      color: $mid-darkest-green;
    }
  }

  .side-menu {
    height: calc(100% - 28px);

    li[isActive='true'], li:hover {
      background: #012E28 !important;

      .iconic-with-text label {
        color: #F1C55E !important;
      }

      .import-plus.menu-icon {
        background-image: url('/assets/icons/import-yellow-icon.svg') !important;
      }

      .export-plus.menu-icon {
        background-image: url('/assets/icons/export-plus-yellow-icon.svg') !important;
      }
      .feeditor-plus.menu-icon {
        background-image: url('/assets/icons/feed-icon-yellow.svg') !important;
      }

    }
  }

  .actual-target {
    &:focus, &:hover, &.media-focus-btn {
      .media-title {
        color: $mid-darkest-green;
      }

      .down-btn .down-icon {
        background-image: url('/assets/icons/dropdown-arrow-green-darker-icon.svg') !important;
      }

      .additional-menu-btn {
        background-image: url('/assets/icons/dots-menu-green-darker-icon.svg') !important;
      }
    }
  }

  .btn-round {
    border: 1px solid $mid-darkest-green;
    background: #1FA59740;

    label {
      color: black;
    }
  }

  .btn-round-box:hover .btn-round {
    background: $mid-darkest-green;
    border: 1px solid $mid-darkest-green;

    label {
      color: white;
    }
  }

  .table-colored-shadow .p-datatable-tbody > tr:nth-child(even) td {
    background: white;
  }

  .p-inputswitch {
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: #D0FAF6 !important;
        border: 1px solid #C4E7E3;

        &::before {
          background: $mid-darkest-green !important;
        }
      }
    }
  }

  .action-icons-bar {
    width: 100%!important;
    .icons-box {
      width: 100%!important;
      .topbar-icon-btn {
        padding-right: 30px!important;
        &.publish-button .publish-label {
          margin-top: 4px;
        }
        &.topbar-action {
          top: -1px;
        }
        .pi:before {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
  }

  .topbar-icon-btn:hover {
    .pi-save::before { //AAA
      background-image: url('/assets/icons/save-green-darker-icon.svg') !important;
    }

    .pi-refresh::before { //AAA
      background-image: url('/assets/icons/cache-green-darker-icon.svg') !important;
    }

    .pi-cloud-download::before {
      background-image: url('/assets/icons/export-green-darker.svg') !important;
    }
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $dark-darkest-green !important;
    background: $dark-darkest-green !important;
  }

  .p-checkbox.pink .p-checkbox-box.p-highlight, .p-checkbox.pink .p-checkbox-box.p-highlight:hover, .p-checkbox-box.p-checkbox-checked, .p-checkbox .p-checkbox-box.p-highlight {
    background: $dark-darkest-green !important;
    border-color: $dark-darkest-green !important;
  }

  .toolbar-action[isActive=true] {
    background: $light-darkest-green !important;
  }

  .dropdown-panel {
    &.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      border-color: $lighter-darkest-green !important;
      background: $lighter-darkest-green !important;

    }
  }

  &.p-autocomplete-panel {
    .p-autocomplete-items .p-autocomplete-item:hover {
      background: $lighter-darkest-green;
    }
  }

  .p-dropdown {
    &.p-focus:not(.p-disabled):not(.p-dropdown-open) {
      border: 1px solid $dark-darkest-green !important;
    }
  }

  .p-inputtext:enabled:focus {
    border-color: $dark-darkest-green !important;
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    &:hover, &:focus, &.p-highlight, :not(.p-highlight):not(.p-disabled):not(.rule-status):hover {
      background: $lighter-darkest-green !important;

      span, div, label {
        background: transparent !important;
      }
    }
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    border-color: transparent !important;
    background: transparent !important;
  }


  &.p-treeselect-panel {
    border: 1px solid $border-separator !important;
    border-radius: 0 0 4px 4px !important;
    margin-top: -1px;

    .p-treeselect {
      box-shadow: none !important;
    }

    .p-tree-selectable {
      padding: 5px 0;

      .p-treenode-children {
        padding: 0;
      }

      .p-treenode {
        padding: 0;

        .p-tree-toggler {
          margin-right: 0;
        }

        .p-treenode-content {
          box-shadow: none !important;
          padding: 0;
          border-radius: 0;
          //height: 31px;
          &.p-highlight {
            background: $lighter-darkest-green;
          }

          &.p-treenode-selectable {
            &:not(.p-highlight):hover {
              box-shadow: none !important;
              background: $lighter-darkest-green;
            }
          }
        }
      }
    }
  }

  .media-search-input {
    border-color: $mid-darkest-green !important;

    &.p-inputtext:enabled:focus {
      border-color: $mid-darkest-green !important;
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-color: $mid-darkest-green !important;
  }

  .media-lists-container::-webkit-scrollbar-thumb {
    background-color: $mid-darkest-green;
  }

  .media-footer-btn {
    .p-button-link .p-button-label {
      color: $mid-darkest-green !important;
    }
  }

  .p-progressbar .p-progressbar-value {
    background-color: $mid-darkest-green;
  }

  .p-confirm-dialog {
    .p-confirm-dialog-accept {
      background-color: $light-darkest-green !important;

      &:hover {
        background-color: #c6e8e5cc !important;
      }
    }
  }
  .loader div, .p-autocomplete-loader {
    color: $mid-darkest-green;
  }
}

//MARKETING_CLOUD
.grey-palette {

}


video {
  object-fit: fill !important;
}

.html5-video-player .video-stream {
  object-fit: fill !important;
}

.video-container iframe, .video-container object, .video-container embed {
  object-fit: fill !important;
}

.html5-main-video {
  object-fit: fill !important;
}

.html5-video-player {
  object-fit: fill !important;
}

.html5-video-player.ended-mode {
  object-fit: fill !important;
}

@media only screen and (max-width: 1120px) {
  .video-player-dialog {
    width: 888px !important;
    height: 564px !important;
  }
}

@media only screen and (max-width: 920px) {
  .video-player-dialog {
    width: 750px !important;
    height: 486px !important;
  }
}

::-webkit-scrollbar {
  height: 10px;
  overflow: visible;
  width: 10px;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  min-height: 10px;
  padding: 100px 0 0;
  box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}


