$main-background-color: #FEF9F6;
$dark-backgroung-color:#2C2C2C;
$border-separator: #D1CAC6;
$light-grey:#A6A6A6;
$border-element: #B5B6BB;
$border-container: #D6CDC8;
$main-text-color:#4E4E4E;
$dark-text-color: #171616;
$footer-text-color:#717171;
$error-message-border:#F6C6CA; 
$error-message:#F8D7DA;
$error-validation:#D43632;
//$error-validation:#D8534F;
//$table-error-text: #EE0f0f;
//$error-text: #FF0000;
$table-linked-text:#2Fa431;
$bg-light-nude: #F2E9E4;
$yellow: #FFDA53;
$white: #FFFFFF;

$facebook-btn-color:#4267B2;
$facebook-btn-color-hover:#35579D;
$google-btn-color:#4285F4;
$google-btn-color-hover:#3971CF;

$dark-blue: #0D6CE5;
$mid-blue: #84AEE4;
$light-blue: #C7DFFF;

$dark-green: #009D8D;
$mid-green: #9EDBC4;
$light-green: #DAFFE6;

$dark-darkest-green: #036059;
$mid-darkest-green: #1FA597; 
$light-darkest-green: #C6E8E5;
$lighter-darkest-green: #1fa5971a;

$dark-purple: #595FFC;
$mid-purple: #A7ABFD;
$light-purple: #E3E4FB;

$dark-orange: #FF6859;
$mid-orange: #EFAEA7;
$light-orange: #FFD0CB;

$dark-pink: #FF7192;
$mid-pink: #FF9AB2;
$light-pink: #FED3DD;

$dark-turkiz: #38DCDC;
$mid-turkiz: #8FE6E6;
$light-turkiz: #CDF2F0;

$dark-darkest-blue: #061278;
$mid-darkest-blue: #A6ABD4; 
$light-darkest-blue: #CACDE8;

$dark-darkest-purple: #562A45;
$mid-darkest-purple: #A13775;
$light-darkest-purple: #b964a00d;

$layout-row-height: 67px;
$modal-radius:8px;
$dialog-width: 700px;


